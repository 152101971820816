@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	padding: 65px 0 40px;
	min-height: 550px;
	
	@media screen and (max-width: 1580px) {
		padding: 40px 0;
	}
	
	.footer-fnav {
		padding: 0 0 65px;
		border-bottom: 1px solid $borderColor;
		margin: 0 0 40px;
		
		@media screen and (max-width: 1580px) {
			padding: 0 25px 40px;
		}
		
		@media screen and (max-width: 950px) {
			padding: 0 15px 40px !important;
		}
		
		&__inner {
			display: flex;
			justify-content: space-between;
			margin: 0 auto;
			max-width: 1520px;
			
			.fnav-top ,
			.fnav-hoyo ,
			.fnav-other {
				width: 18%;
			}

			.fnav-kukaku {
				width: 40%;
			}
			
			.fnav-other {
				margin: 0;
			}
			
			&__list {
				margin: 0 2.5% 0 0;
				padding: 0 2.5% 0 0;
				border-right: 1px dotted $borderColor;
				
				&__item {
					margin: 0 0 15px;
					
					&:last-of-type {
						margin: 0;
					}
					
					&__link {
						margin: 0 0 10px;
						display: inline-block;
						text-decoration: none;
						@include font-bold;
						line-height: 1.5;
						
						@media screen and (max-width: 1150px) {
							font-size: 1.5rem;
						}

						&:hover {
							text-decoration: underline;
						}
						
						&__text {
							@include font-bold;
							
							&__sub {
								font-size: $m;
								
								@media screen and (max-width: 1200px) {
									font-size: $xs;
								}
							}
						}
					}
					
					&__bnr {
						margin: 15px 0 0;
						display: block;
					}
					
					&__sub {
						margin: 0 0 20px;
						
						&__item {
							line-height: 1.2;

							&:not(.footer-fnav__inner__list__item__sub__item--ttl) {
								margin: 0 0 13px;

								@media screen and (max-width: 1200px) {
									margin: 0 0 8px;
								}
							}
							
							
							&--ttl {
								@include font-bold;
								margin: 25px 0 13px;
								
								&:first-of-type {
									margin: 0 0 13px;
								}
								
								.footer-fnav {
									&__inner {
										&__list {
											&__item {
												&__sub {
													&__item {
														&__link {
															font-size: $m;
															
															@media screen and (max-width: 1200px) {
																font-size: $s;
															}
														}
													}
												}
											}
										}
									}
								}
							}
							
							&__link {
								font-size: 1.5rem;
								text-decoration: none;
								color: $textColor;
								
								@media screen and (max-width: 1200px) {
									font-size: $xxs;
								}
																
								&:hover {
									text-decoration: underline;
								}
								
								.footer-br {
									display: none;
									
									@media screen and (max-width: 1200px) {
										display: block !important;
									}
								}
							}
						}
					}
				}
			}

			.fnav-kukaku {
				/*width: calc((100% / 5) * 2);*/
				
				&__inner {
					display: flex;
					justify-content: space-between;
				}
				
				.footer-fnav {
					&__inner {
						&__list {
							&__item {
								width: 100%;

								&__link {
									&__text {
									}
								}
								
								&__sub {
									width: calc(100% / 2);
									margin: 0 6% 0 0;
									padding: 0 6% 0 0;
									border-right: 1px dotted $borderColor;
									
									&:last-child {
										margin: 0;
										padding: 0;
										border-right: none;
									}
								}
							}
						}
					}
				}
			}	
		}
	}
	
	.footer-inner {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin: 0 auto;
		max-width: 1520px;
		
		@media screen and (max-width: 1580px) {
			margin: 0 25px;
		}
		
		@media screen and (max-width: 950px) {
			margin: 0 15px !important;
		}
		
		&__logo {
			flex: 1 1 auto;
			display: flex;
			align-items: flex-end;
			
			&__right {
				flex: 0 0 300px;

				&__text {
					font-size: $xs;
					color: #747474;
					margin: 0 0 5px;
				}
				
				&__link {
					width: 100%;
					display: block;

					@media screen and (max-width: 1420px) {
						width: 90%;
					}
				}
			}
			
			&__address {
				font-size: 1.5rem;
				margin: 0 0 0 35px;
				display: inline-block;
				
				@media screen and (max-width: 1420px) {
					font-size: $xs;
				}
				
				&__br {
					display: none;
				}
				
				@media screen and (max-width: 1420px) {
					margin: 0 0 0 20px;
					
					&__br {
						display: block !important;
					}
				}
			}
		}
		
		&__copyright {
			flex: 0 0 auto;
			font-size: $s;
			color: rgba($textColor, 0.6);
			
			&__sns {
				display: flex;
				justify-content: flex-end;
				
				&__link {
					margin: 0 5px;
					font-size: 3.0rem;
					text-decoration: none;
					color: #000;
				}
			}
		}
	}

}



.fixed-menu {
	position: fixed;
	top: 50%;
	right: 0;
	z-index: 60;
	width: 290px;
	background: $white;
	border-radius: 3px 0 0 3px;
	box-shadow: 0 0 6px rgba(#000, .16);
	transform: translate(230px, -50%);
	@include transition;
	
	&:hover {
		transform: translate(0, -50%);
		
		.fixed-menu__inner__link__text {
			opacity: 1;
		}
	}
	&__inner {
		display: flex;
		flex-direction: column;
		
		&__link {
			display: flex;
			align-items: center;
			padding: 20px;
			text-decoration: none;
			@include transition;
			
			&:not(:last-of-type) {
				border-bottom: 1px solid $borderColor;
			}
			&--catalogue {
				border-radius: 0 0 0 3px;
			}
			&--contact {
				border-radius: 3px 0 0 0;
			}
			&--tel {
				display: none;
			}
			&__icon {
				margin: 0 12px 0 0;
				font-size: 2.6rem;
				@include transition;
			}
			&__text {
				color: $textColor;
				opacity: 0;
				@include font-bold;
				@include transition;
			}
			&:hover {
				background: $baseColor;
			}
			&:hover &__icon,
			&:hover &__text {
				color: $white;
			}
		}
	}
}