@charset "utf-8";
/* ============================================================ */
/* .beginner-btn */
/* ============================================================ */
.beginner-btn {
	margin: 20px 0 0;

	&__item {
		margin: 0 auto 10px;
		width: 85%;

		.btn {
			width: 100%;
			min-width: auto !important;
		}
	}
}


/* ============================================================ */
/* #beginner_index */
/* ============================================================ */
#beginner_index {
	.concept-ttl {
		color: $baseColor;
		text-align: center;
		margin: 0 0 30px;
		padding: 70px 0 45px;
		@include font-bold;
		font-size: $xl;
		line-height: 1.4;
		position: relative;
		
		&::before {
			background: url("/common/img/beginner/bg_lead_top.png") top center no-repeat;
			background-size: contain;
			width: 175px;
			height: 63px;
			content: '';
			display: inline-block;
			@include centering-elements(true, false);
			top: 0;
			z-index: 1;
		}
		
		&::after {
			background: url("/common/img/beginner/bg_lead_bottom.png") bottom center no-repeat;
			background-size: contain;
			width: 175px;
			height: 40px;
			content: '';
			display: inline-block;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
			z-index: 1;
		}
		
		&__text {
			color: $textColor;
			font-weight: normal;
			font-size: 1.5rem;
			line-height: 1.6 !important;
			display: inline-block;
			margin: 5px 0 0;
		}
	}
	
	.concept-img {
		margin: 0 0 20px;
	}
			
	.concept-hanaoka {
		margin: 0 auto 20px;
		
		&__name {
			max-width: 130px;
			margin: 10px auto 0;
		}
	}
}

/* ============================================================ */
/* #beginner_choose */
/* ============================================================ */
#beginner_choose {
	.block {
		&:first-of-type {
			margin-top: 30px;
		}
	}
	
	.choose-img {
		margin: 0 0 20px;
		
		&__innner {
			margin: 0 0 15px;
			
			&__text {
				margin: 10px 0 0;
				text-align: center;
			}
		}
	}
	
	.hikaku-text {
		font-size: $s;
	}
	
	.column3 {
		div {
			margin-bottom: 25px !important;
		}
	}
	
	.box {
		margin: 20px 0 0;
	}
	
	.column3 {
		margin: 0;
		
		&__inner {
			border-bottom: 1px dotted $borderColor;
			padding: 0 0 20px;
		}
	}
	
	.choose-price {
		&__item {
			padding: 0 0 20px;
			margin: 0 0 20px;
			border-bottom: 1px dotted $borderColor;
			
			&:last-of-type {
				margin: 0 0 30px;
			}
			
			&__list {
				font-size: $s;
			}
			
			.box {
				margin: 0 0 20px;
			}			
		}
	}

}

/* ============================================================ */
/* #beginner_kaisou */
/* ============================================================ */
#beginner_kaisou {
	.panel {
		.link-arrow {
			margin: 0 0 5px;

			&:last-of-type {
				margin: 0;
			}
		}
		&.select-place {
			.flex-column {
				margin-top: 20px;
				padding-top: 20px;
				border-top: 1px dotted $borderColor;
				
			}
		}
		&__body {
			&__kouji {
				margin: 15px 0;
				
				&__item {
					margin: 0 0 40px;
					position: relative;
					
					&:last-of-type {
						margin: 0;
						
						&:after {
							display: none;
						}
					}
					
					&:after {
						position: absolute;
						bottom: -32px;
						left: calc(50% - 20px);
						width: 0;
						height: 0;
						border-color: $baseColor transparent transparent;
						border-style: solid;
						border-width: 20px 20px 0;
						content: "";
					}
				}
			}
		}
	}
}

/* ============================================================ */
/* #beginner_point */
/* ============================================================ */
#beginner_point {
	.no-cost {
		&__head {
			&__item:not(:empty) {
				width: 200px;
				text-align: center;
			}
		}
		&__body {
			&__text {
				font-size: $s;
			}
		}
	}
	.kukaku-link {
		display: block;
		max-width: 300px;
		margin: 0 auto;
		white-space: nowrap;
		text-decoration: none;
		
		&__img {
			max-width: 100%;
			margin: 0 0 15px;
			@include transition;
		}
		&__txt {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $textColor;
			
			.ico-arrow {
				flex: 0 0 auto;
				margin: 0 8px 0 0;
			}
		}
		&:hover {
			.kukaku-link__img {
				@include opacity;
			}
			.kukaku-link__txt {
				color: $baseColor;
			}
		}
	}
}
