@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.contents {
		margin: 65px 0 0;
		padding: 5px 0 0;
	}
	
	/* --------------------------------------------------- */
	/* index-visual */
	/* --------------------------------------------------- */
	.index-visual {
		width: 100%;
		height: 320px;
		position: relative;
		
		&__inner {
			height: 320px;
			
			&__slider {
				height: 320px;
				overflow: hidden;
				position: relative;
				
				.slick-slide {
				}
				
				.slick-prev {
					position: absolute;
					top: 50%;
					left: 3%;
					transform: translate(0, -50%);
					z-index: 2;
					color: $white;
					border: none;
					background: rgba($white,0.85);
					box-shadow: 0 0 7px 0 rgba(0,0,0,0.7);
					display: flex;
					align-items: center;
					justify-content: center;
					width: 35px;
					height: 35px;
										
					&::before {
						color: #000 !important;
						font-size: $xxl;
					}
					
					&:hover {
						&::before {
							color: #000 !important;
							opacity: 1;
						}
					}				
				}
				
				.slick-next {
					position: absolute;
					top: 50%;
					right: 3%;
					transform: translate(0, -50%);
					z-index: 2;
					color: $white;
					border: none;
					background: rgba($white,0.85);
					box-shadow: 0 0 7px 0 rgba(0,0,0,0.7);
					display: flex;
					align-items: center;
					justify-content: center;
					width: 35px;
					height: 35px;
										
					&::before {
						color: #000 !important;
						font-size: $xxl;
					}
					
					&:hover {
						&::before {
							color: #000 !important;
							opacity: 1;
						}
					}	
				}
				
				&__item {
					position: relative;
					
					&__link {
						color: $textColor;
						display: block;
					}
					
					&__text {
						height: 100%;
						width: 100%;
						z-index: 3;
						padding: 35px 10px;
						position: absolute;
						left: 0;
						bottom: 0;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: flex-end;
						text-align: center;
												
						&__cap {
							&__bg {
								margin: 0 0 3px;
								padding: 0 0 0 1em;
								line-height: 1.5;
								font-size: $s;
								@include font-bold;
								color: $white;
								display: inline-block;
								text-shadow: 0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5);
								
								_:-ms-fullscreen, :root & {
									padding: 7px 5px 2px;
								}
								&--indent {
									text-align: left;
									text-indent: -1em;
								}
							}
						}
					}
					
					&__img {
						overflow: hidden;
						height: 100%;
					}
					
					.slick-list ,
					.slick-track {
						height: 320px;
					}

					.object-fit-img {
						height: 320px;
						object-fit: cover;
						object-position: center center;
						font-family: 'object-fit: cover; object-position: center center;'
					}
				}
			}
			
			.slick-dots {
				margin: -38px 0 0;
				position: relative;
				z-index: 2;
				
				li {
					button {
						width: 12px;
						height: 12px;
						
						&::before {
							width: 12px;
							height: 12px;
						}
					}
				}
			}
		}
	}

	// visual09：永代供養墓（4名）用スタイル
	.kuyou-four {
		&__inner {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			padding: 15px 0 35px;
		}
		&__txt1,
		&__txt2 {
			display: none;
		}
		&__sp {
			flex: 1 1 auto;
			position: relative;
	
			&__inner {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			.object-fit-img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				font-family: 'object-fit: contain;';
			}
		}
		&__btn {
			flex: 0 0 auto;
			display: flex;
			justify-content: center;
			margin: 15px 0 0;
		}
	}	

	// visual11：永代供養墓（4名）用スタイル
	.grave-consultation {
		&__inner {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			padding: 15px 0 35px;
		}
		&__txt {
			display: none;
		}
		&__sp {
			flex: 1 1 auto;
			position: relative;
	
			&__inner {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			.object-fit-img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				font-family: 'object-fit: contain;';
				margin: 30px 0 0;
			}
		}
	}	
	
	/* --------------------------------------------------- */
	/* index-bnr */
	/* --------------------------------------------------- */
	.index-bnr {
		margin: 15px auto 0;
		text-align: center;
		width: 70%;
		
		&__item {
		}
	}
	
	
	/* --------------------------------------------------- */
	/* index-top-block */
	/* --------------------------------------------------- */
	.index-top-block {
		width: 100%;
	}
	
	
	/* --------------------------------------------------- */
	/* index-top-bnr */
	/* --------------------------------------------------- */
	.index-top-bnr {
		margin: 15px 0 10px;
		padding: 0 15px;
		display: block;
	}
	
	
	/* --------------------------------------------------- */
	/* index-important */
	/* --------------------------------------------------- */
	.index-important {
		margin: 20px 15px 10px;
		padding: 10px 15px 15px;
				
		&__ttl {
			color: $baseColor;
			text-align: center;
			border-bottom: 1px dotted rgba($baseColor, 0.5);
			padding: 0 0 6px;
			margin: 0 0 10px;
			font-size: 1.7rem;
			@include font-bold;
		}
		
		&__list {
			&__item {
				margin: 0 0 10px;
				font-size: $s;
				line-height: 1.5;
				
				&:last-of-type {
					margin: 0;
				}
				
				.icon-angle-right {
					color: $baseColor;
				}
				
				&__link {
					margin: 0 0 0 5px;
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-news */
	/* --------------------------------------------------- */
	.index-news {
		border-top: 5px solid #ffebec;
		padding: 20px 0 0;
		margin: 0 0 40px;
		
		&__btn {
			margin: 15px auto 0;
			text-align: center;
			.btn {
				height: 45px;
			}
		}
		
		&__ttl {
			margin: 0 0 15px;
			text-align: center;
			
			.ttl02 {
				text-align: center;
				margin: 0 0 5px;
			}
			
			&__sns {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 0 10px;

				&__link {
					margin: 0 5px;
					font-size: 3.0rem;
					text-decoration: none;
					color: #000;
				}
			}
		}
		
		&__inner {
			width: calc(100% - 30px);
			margin: 0 auto;
		}
		
		&-list {
			border-top: 1px dotted $borderColor;
			&__item {
				width: 100%;
				border-bottom: 1px dotted $borderColor;
			}
			&__link {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				text-decoration: none;
				color: $textColor;
				padding: 15px 0;
				&:hover {
					.index-news-list__ttl {
						text-decoration: none;
					}
				}
			}
			&__date {
				flex: 0 0 auto;
				min-width: 8em;
				margin-right: 15px;
			}

			&__category {
				flex: 0 0 auto;
				min-width: 14%;
				margin-right: 30px;
				text-align: center;
			}

			&__ttl {
				width: 100%;
				margin-top: 5px;
				flex: 1 1 auto;
				text-decoration: underline;
				color: $baseColor;
				line-height: 1.6;
				word-break: break-all;
				@include transition;
			}

			
		}

	}
	
	/* --------------------------------------------------- */
	/* index-kukaku */
	/* --------------------------------------------------- */
	.index-kukaku {
		background: url("/common/img/index/bg_kukaku.jpg") 0 0 repeat;
		margin-top: 40px;
		padding: 30px 0 45px;
		position: relative;
				
		&::before {
			background: url("/common/img/index/bg_kukaku_leaf.png") top center no-repeat;
			background-size: contain;
			width: 100%;
			height: 500px;
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}
		
		&__inner {
			position: relative;
			z-index: 2;
			margin: 0 15px;
			
			.ttl02 {
				text-align: center;
			}
			
			&__list {
				margin: 0 0 25px;
				
				&:last-of-type {
					margin: 0;
					
					.index-kukaku {
						&__inner {
							&__list {
								&__item {
									&:last-of-type {
										margin: 0;
									}
								}
							}
						}
					}
				}
				
				&__item {
					margin: 0 0 20px;
						
					&__block {
						background: url("/common/img/index/bg_kukaku_flower02.png") right bottom no-repeat $white;
						background-size: 25%;
						@include radius(3);
						box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
						padding: 20px 15px 20px;
						
						&__link {
							text-decoration: none;
							color: $textColor;
							font-size: $s;
							line-height: 1.5;
							display: block;
							text-align: center;
							
							&__top {
								margin: 0 0 10px;
								
								&__img {
									margin: 0 0 10px;
								}

								&__text {
									text-align: left;
								}
							}
						}
					}

				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-guide */
	/* --------------------------------------------------- */
	.index-guide {
		margin-top: 40px;
		padding: 0 0 40px;
		position: relative;
		
		&::before {
			background: url("/common/img/index/bg_guide_left.png") top left no-repeat;
			background-size: contain;
			width: 40%;
			height: 40%;
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}
		
		&::after {
			background: url("/common/img/index/bg_guide_right.png") top right no-repeat;
			background-size: contain;
			width: 40%;
			height: 40%;
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
		}
		
		&__inner {
			position: relative;
			z-index: 2;
			margin: 0 15px 30px;
			padding: 30px 0 0;
			
			.ttl02 {
				text-align: center;
			}
			
			&__list {
				&__item {
					margin: 0 0 25px;
					
					&__link {
						display: block;
						text-decoration: none;
						
						&__img {
							position: relative;
							margin: 0 0 15px;
						}
						
						&__text {
							display: flex;
							align-items: center;
							justify-content: center;
							color: $textColor;
							font-size: $m;
							@include font-bold;
							line-height: 1.4;
														
							.ico-arrow {
								margin: 0 10px 0 0;
							}
						}
					}
				}
			}
		}
		
		&__choice {
			margin: 0 15px;
			
			&__ttl {
				margin: 0 0 30px;
				/*background: $baseColor;
				color: $white;
				text-align: center;
				position: relative;
				width: 100%;
				margin: -15px 0 20px;
				font-size: $m;
				@include font-bold;
				overflow: hidden;
				line-height: 1.4;
								
				&::before, &::after {
					content:'';
					position: absolute;
					width: 13px;
					height: 13px;
					border-radius: 50%;
					background: $white;
				}
				
				&::before {
					top: -6.5px;
					left: -6.5px;
				}

				&::after {
					top: -6.5px;
					right: -6.5px;
				}
				
				&__inner {
					position: relative;
					width: 100%;
					display: block;
					padding: 5px 10px;
					overflow: hidden;
					
					_:-ms-fullscreen, :root & {
						padding: 7px 10px 3px;
					}
					
					&::before, &::after {
						content:'';
						position: absolute;
						width: 13px;
						height: 13px;
						border-radius: 50%;
						background: $white;
					}
					
					&::before {
						bottom: -6.5px;
						left: -6.5px;
					}

					&::after {
						bottom: -6.5px;
						right: -6.5px;
					}
				}*/
			}
			
			&__inner {
				&__item {
					margin: 0 0 20px;
					
					&:last-of-type {
						margin: 0;
					}
					
					&__link {
						text-decoration: none;
						display: block;
						cursor: pointer;
						
						&__img {
							margin: 0 0 15px;
							position: relative;
							
							&::after, &::before {
								content: '';
								width: 25px;
								height: 25px;
								position: absolute;
								display: inline-block;
							}

							&::before {
								border-left: 6px solid #C9B997;
								border-top: 6px solid #C9B997;
								top: -7px;
								left: -7px;
							}
							
							&::after {
								border-right: 6px solid #C9B997;
								border-bottom: 6px solid #C9B997;
								bottom: -7px;
								right: -7px;
							}
						}
						
						&__text {
							display: flex;
							justify-content: center;
							color: $textColor;
							font-size: $m;
							@include font-bold;
							line-height: 1.4;
							
							.ico-arrow {
								margin: -3px 10px 0 0;
							}
							
							&__ttl {
								max-width: calc(100% - 32px);
							}
						}
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-faq */
	/* --------------------------------------------------- */
	.index-faq {
		position: relative;
		padding: 150px 0 0;
		margin-top: 40px;
		background: #FFF8FA;
		
		&::before {
			background: url("/common/img/index/bg_faq_sp.jpg") top center no-repeat;
			background-size: cover;
			width: 100%;
			height: 170px;
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0);
			z-index: 1;
		}
		
		&__block {
			position: relative;
			z-index: 2;
			background: $white;
			box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
			width: calc(100% - 15px);
			padding: 25px 0;
						
			&__inner {
				margin: 0 15px;
				background: url("/common/img/index/bg_faq_inner.png") left bottom no-repeat;
				background-size: 40%;
				
				&__ttl {
					text-align: center;
					margin: 0 0 20px;
					
					.ttl02 {
						margin: 0 0 15px;
					}
					
					.btn {
						height: 45px;
					}
				}
				
				&__list {
					&__block {
						position: relative;
						z-index: 3;

						&__item {
							margin: 0 0 15px;
							
							&__btn {
								text-align: center;
								
								.btn {
									width: 50%;
								}
							}
							
							&__question {
								margin: 0 0 10px;
								
								&__detail {
									border-bottom: 1px dotted $borderColorBeige;
									padding: 10px 15px;
									
									.icon-angle-right {
										margin: 0 10px 0 0;
									}
									
									&__link {
										text-decoration: none;
										@include font-bold;
										line-height: 1.5;
										font-size: $s;
										display: flex;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* message */
	/* --------------------------------------------------- */
	.index-message {
		padding: 30px 0 55px;
		position: relative;
		margin: 40px 15px 0;
		
		.ttl02 {
			text-align: center;
			margin: 0 0 25px;
			line-height: 1.6;
		}
		&__right {
			width: 100%;
			margin: 0;
			position: relative;
			z-index: 1;
			
			&::before {
				content: "";
				width: 155px;
				height: 239px;
				background: url("/common/img/index/bg_message_right01.png") 0 0 no-repeat;
				background-size: cover;
				display: block;
				position: absolute;
				top: -95px;
				right: -10px;
			}
		}
		&__pic01 {
			z-index: 1;
			position: relative;
			
			.__elem {
				width: 100%;
			}
			
		}
		&__left {
			width: 100%;
			margin: 0;
			position: relative;
			
			&::before {
				content: "";
				width: 130px;
				height: 133px;
				background: url("/common/img/index/bg_message_left01.png") 0 0 no-repeat;
				background-size: cover;
				display: block;
				position: absolute;
				top: 140px;
				left: -5px;
			}
			&::after {
				content: "";
				width: 345px;
				height: 213px;
				background: url("/common/img/index/bg_message_right02.png") 0 0 no-repeat;
				background-size: cover;
				display: block;
				position: absolute;
				bottom: -100px;
				right: -34%;
				z-index: -1;
			}
		}
		&__txt {
			font-size: $xxl;
			background: #FEF1F6;
			text-align: center;
			padding: 180px 15px 30px;
			margin: -150px 15px 0 -15px;
			
			.__txt01 {
				background: url("/common/img/index/ico_message.png") center bottom no-repeat;
				background-size: 60px auto;
				margin: 0 0 15px;
				padding: 0 0 45px;
				@include font-bold;
				font-size: $m;
			}
			.__txt02 {
				@include font-bold;
				font-size: $m;
			}
		}
		&__pic02 {
			margin: 20px 0 0;
			padding: 0 15px;
			
			.__elem {
				width: 100%;
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-feature */
	/* --------------------------------------------------- */
	.index-feature {
		padding: 0 0 30px;
		position: relative;
		
		.ttl02 {
			text-align: center;
			margin: 0 0 35px;
			
			&__num {
				margin: 0 -15px 0 5px;
				.__elem {
					width: 48px;
				}
			}
		}
		
		&__inner {
			padding: 0 15px;
		}
		&-list {
			&-lg,
			&-sm {
				&__item {
					margin: 0 0 30px;
				}
				&__ttl {
					color: $baseColor;
					@include font-bold;
					font-size: $l;
					text-align: center;
					margin: 0 0 15px;
					background: url("/common/img/index/bg_feature.png") right bottom no-repeat;
					background-size: 50px auto;
					position: relative;
					
					
				}
				&__num {
					height: 150px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: -68px 0 -40px;
					position: relative;
					background: url("/common/img/index/bg_feature_num.png") center center no-repeat;
					.__elem {
						width: 12%;
						max-width: 44px;
					}
				}
				&__main {
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					font-size: $l;
				}
				&__txt {
					@media screen and (max-width: 1580px) {
						font-size: $m;
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-support */
	/* --------------------------------------------------- */
	.index-support {
		background: url("/common/img/index/bg_news.png") top left no-repeat;
		background-size: 40%;
		text-align: center;
		margin: 30px 0 0;
		padding: 0 15px;
		
		&__btn {
			text-align: center;
		}
		
		&__inner {
			width: 100%;
		}
		
		&-item {
			width: 100%;
			margin-bottom: 15px;
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
			text-decoration: none;
			border-radius: 3px;
			overflow: hidden;
			display: block;
			@include transition;
			&:hover {
				@include opacity;
			}
			&__ttl {
				color: $white;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 15px 10px;
				font-size: $xs;
				line-height: 1.4;
				&._bg-01_ {
					background: url("/common/img/index/bg_support_01.png") no-repeat center center;
					background-size: cover;
					text-shadow: 0 0 10px #ea6042;
				}
				&._bg-02_ {
					background: url("/common/img/index/bg_support_02.png") no-repeat center center;
					background-size: cover;
					text-shadow: 0 0 10px #649d35;
				}
				&._bg-03_ {
					background: url("/common/img/index/bg_support_03.png") no-repeat center center;
					background-size: cover;
					text-shadow: 0 0 10px #ec5178;
				}
				&._bg-04_ {
					background: url("/common/img/index/bg_support_04.png") no-repeat center center;
					background-size: cover;
					text-shadow: 0 0 10px #be6b38;
				}
				&._bg-05_ {
					background: url("/common/img/index/bg_support_05.png") no-repeat center center;
					background-size: cover;
					text-shadow: 0 0 10px #ef8c01;
				}
				.__lg {
					font-size: $m;
					@include font-bold;
				}
			}
		}
	}

	/* --------------------------------------------------- */
	/* index-consultation-bnr */
	/* --------------------------------------------------- */
	.index-consultation-bnr {
		max-width: 100%;
		background: #FFF6F9;
		margin: 40px 15px 0;
		padding: 15px;
		
		&__link {
			width: 100%;
			display: block;
			margin: 0 auto;
		}
	}
	
	
	/* --------------------------------------------------- */
	/* index-movie */
	/* --------------------------------------------------- */
	.index-movie {
		background: #ffebec;
		margin-top: 40px;
		padding: 30px 0 45px;
				
		&__inner {
			position: relative;
			z-index: 2;
			margin: 0 15px;
			
			.ttl02 {
				text-align: center;
			}
		}
		&__box {
			margin: 0 auto;
		}

	}
	

}