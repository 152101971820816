@charset "utf-8";
/* ============================================================ */
/* #support */
/* ============================================================ */
.support {
	.support-type {
		&__box {
			border: 4px solid #FDE7E9;
			border-top: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		&--eidai {
			.ttl05 {
				color: #F27272;
				border-bottom-color: #F89F9F;
			}
			.list > *::before {
				background: #F27272;
			}
		}
		&--jumoku {
			.support-type__box {
				border-color: #E7F4D3;
			}
			.ttl05 {
				color: #6CA136;
				border-bottom-color: #92C568;
			}
			.list > *::before {
				background: #6CA136;
			}
		}
		&--gendai {
			.support-type__box {
				border-color: #F4DAC8;
			}
			.ttl05 {
				color: #CC7B4B;
				border-bottom-color: #E2A97F;
			}
			.list > *::before {
				background: #CC7B4B;
			}
		}
		&--hakajimai {
			.support-type__box {
				border-color: #FADDE7;
			}
			.ttl05 {
				border-bottom-color: #F56797;
			}
			.list > *::before {
				background: #F56797;
			}
		}
		&--pet {
			.support-type__box {
				border-color: #ffe0ab;
			}
			.ttl05 {
				color: #f4a101;
				border-bottom-color: #f4a101;
			}
			.list > *::before {
				background: #f4a101;
			}
		}
	}
}
