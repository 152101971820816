@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	background: $white;
	position: fixed;
	width: 100%;
	z-index: 500;
	
	.header-top {
		display: flex;
		justify-content: flex-end;
		/*justify-content: space-between;*/
		align-items: center;
		padding: 10px 50px 10px 0;
		border-bottom: 1px solid $borderColor;
		background: $gray;
		
		@media screen and (max-width: 1580px) {
			padding: 10px 25px 10px 0;
		}
		
		@media screen and (max-width: 950px) {
			padding: 10px 15px 10px 0 !important;
		}
		
		&__tab {
			display: flex;
			width: 35%;
			
			@media screen and (max-width: 1390px) {
				width: 45%;
			}
			
			@media screen and (max-width: 1080px) {
				width: 50% !important;
			}
			
			&__item {
				width: calc(100% / 3);
				display: flex;
				border-right: 1px solid $borderColor;
				
				.ueda & {
					.angel-ueda {
						background: $baseColor;
						color: $white;
						
						.header-top__tab__item__link__text__sub {
							color: $white;
						}
					}

					.angel-home {
						color: #4084D2;
						
						&:hover {
							background: #bbd9fb;
						}
					}

					.angel-nagano {
						color: #208415;
						
						&:hover {
							background: #b7e5b2;
						}
					}
				}
				
				&__link {
					display: flex;
					align-items: center;
					font-size: 1.5rem;
					width: 100%;
					padding: 5px 10px;
					text-align: center;
					text-decoration: none;
					line-height: 1.4;
					@include transition;
					
					&:hover {
						background: $baseColor;
						color: $white;
						@include opacity;
						opacity: 1;
					}
					
					&__text {
						display: flex;
						flex-direction: column;
						justify-content: center;
						width: 100%;
						height: 100%;
						@include font-bold;
						
						&__sub {
							font-size: $xs;
							color: $textColor;
							@include font-normal;
							
							@media screen and (max-width: 1420px) {
								font-size: $xxs;
							}
						}
					}
				}
			}
		}
		
		&__hnav {
			width: 34%;
			margin: 0 -10px;
			
			@media screen and (max-width: 1615px) {
				width: 40%;
			}
			
			@media screen and (max-width: 1290px) {
				width: 50% !important;
			}
			
			&__inner {
				display: flex;
				justify-content: flex-end;
				
				&__item {
					font-size: $s;
					margin: 0 10px;
					text-decoration: none;
					@include transition;
					display: flex;
					align-items: center;
					color: $textColor;
					
					@media screen and (max-width: 1420px) {
						font-size: $xs;
					}
					
					@media screen and (max-width: 1050px) {
						font-size: $xxs;
						padding: 0 5px;
						margin: 0 5px !important;
					}
					
					&:last-of-type ,
					&:nth-last-of-type(2) {
						margin-bottom: 0;
					}
					
					&:hover {
						@include opacity;
						opacity: 1;
						color: $baseColor;
						
						.header-inner {
							&__hnav {
								&__inner {
									&__item {
										&__text {
											color: $white;
										}
									}
								}
							}
						}
					}
					
					.icon-angle-right {
						margin: 0 5px 0 0;
						color: $baseColor;
					}
					
					&__text {
					}
				}
			}
		}
	}
	
	
	
	
	.header-inner {
		padding: 15px 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: $white;
		height: 100px;
		
		@media screen and (max-width: 1580px) {
			padding: 15px 25px;
		}
		
		@media screen and (max-width: 1100px) {
			padding: 15px 10px !important;
		}
		
		&__logo {
			width: 17%;
			
			/*@media screen and (max-width: 1615px) {
				width: 25%;
			}
			*/
			
			&__link {
				display: block;
				
				/*@media screen and (max-width: 1150px) {
					width: 90% !important;
				}*/
			}
			
			&__text {
				font-size: $xs;
				color: #747474;
				margin: 0 0 3px;
				font-weight: 300;
				
				@media screen and (max-width: 1150px) {
					font-size: $xxxs;
				}
				
				.logo-br {
					display: none;
					
					@media screen and (max-width: 955px) {
						display: block;
					}
				}
			}
		}
	}
	
	.header-gnav {
		background: $white;
		
		/*@media screen and (max-width: 1615px) {
			width: 75%;
		}*/
		
		&__inner {
			&__list {
				display: flex;
				justify-content: flex-end;
				
				&__item {
					border-right: 1px solid $borderColor;
					
					&:first-of-type {
						border-left: 1px solid $borderColor;
					}
					
					&__link {
						padding: 0 20px;
						font-size: $l;
						color: $textColor;
						text-decoration: none;	
						@include font-bold;
						@include transition;
						
						@media screen and (max-width: 1615px) {
							/*font-size: 1.5rem;*/
							padding: 0 10px;
						}
						
						@media screen and (max-width: 1365px) {
							font-size: $m !important;
						}
						
						@media screen and (max-width: 1225px) {
							font-size: $s !important;
							padding: 0 5px !important;
						}
						
						&.current ,
						&:hover {
							color: $baseColor;
							@include opacity;
							opacity: 1;
						}
						
						.beginner &.gnav-beginner,
						.kukaku &.gnav-design,
						.hoyo &.gnav-hoyo,
						.service &.gnav-service,
						.customer &.gnav-customer,
						.access &.gnav-access,
						.faq &.gnav-faq {
							color: $baseColor;
						}
						
						&__ico {
						}
						
						&__text {
						} 
					}
					
					
				}
			}
		}
	}
}

.is-animation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
	box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
	height: 100px;
	
	.header-top {
		display: none;
	}
}
#index {
	.header {
		.gnav-top {
			color: $baseColor;
		}
	}
}