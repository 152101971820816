@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	padding: 0 0 166px;
	border-top: 1px solid $borderColor;

	.footer-fnav {
		margin: 0 0 20px;
		
		&__bnr {
			margin: 20px auto 0;
			display: block;
			width: 70%;
		}
		
		&__inner {
			&__list {
				&__item {
					border-bottom: 1px solid $borderColor;

					&__link {
						display: flex;
						align-items: center;
						padding: 15px;
						text-decoration: none;
						@include font-bold;
						position: relative;

						_:-ms-fullscreen, :root & {
							padding: 17px 15px 12px;
						}

						&__ico {
							margin: 0 10px 0 0;
						}
					}
					
					.fnav-accordion {
						&::before {
							display: block;
							position: absolute;
							top: 50%;
							right: 15px;
							transform: translate(0, -50%);
							color: $baseColor;
							font-family: 'icomoon';
							line-height: 1.0;
							content: "\e90d";
							font-size: $l;
						}
						
						&.active {
							&::before {
									content: "\e90e";
							}
							
							.fnav-kukaku {
								&__inner {
									display: block !important;
									
									.footer-fnav {
										&__inner {
											&__list {
												&__item {
													&__sub {
														display: block !important;
													}
												}
											}
										}
									}
								}
							}
						}
					}
					
					.fnav-kukaku {
						&__inner {
							display: none;
							
							&__list {
								&__item {
									&__sub {
										display: block !important;
									}
								}
							}
						}
					}
					
					.fnav-common {
						display: none;
					}
					
					&__sub {
						background: rgba($borderColor, 0.3);
						border-top: 1px dotted $borderColor;
						
						&__item {
							border-bottom: 1px dotted $borderColor;
							
							&:last-of-type {
								border-bottom: none;
							}
							
							&__link {
								color: $textColor;
								display: flex;
								padding: 12px 15px 12px 30px;
								text-decoration: none;
								font-size: $s;
								line-height: 1.5;
								
								.icon-angle-right {
									margin: 3px 10px 0 0;
									display: inline-block;
									color: $baseColor;
								}
							}
						}
					}
				}
			}
		}
	}
	
	.footer-inner {
		padding: 0 15px;
		
		&__logo {
			margin: 0 0 15px;
			
			&__right {
				&__text {
					font-size: $xxs;
					color: #747474;
					margin: 0 0 5px;
					text-align: center;
				}
				
				&__link {
					width: 195px;
					margin: 0 auto 10px;
					display: block;
				}
			}
			
			&__address {
				font-size: $xs;
				text-align: center;
			}
		}
		
		&__copyright {
			font-size: $xxs;
			color: rgba($textColor, 0.6);
			text-align: center;
			line-height: 1.4;
			
			&__sns {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 0 15px;
				
				&__link {
					margin: 0 5px;
					font-size: 3.0rem;
					text-decoration: none;
					color: #000;
				}
			}
		}
	}
}


.fixed-menu {
	background: $lightColor;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 146px;
	z-index: 500;
	border-top: 1px solid #f3d2dd;
	
	&__bnr {
		background: $white;
		padding: 15px;
		text-align: center;
		.__elem {
			width: 100%;
			max-width: 345px;
		}
	}
	&__inner {
		display: flex;
		align-items: center;
		
		&__link {
			width: calc(100% / 4);
			border-right: 1px dotted #f3d2dd;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 60px;
			text-decoration: none;
			font-size: $xxs;
			text-align: center;
			color: $textColor;
			line-height: 1.2;
			
			&:last-of-type {
				border-right: none;
			}
			
			&__icon {
				color: $baseColor !important;
				font-size: $l;
				margin: 0 0 2px;
				line-height: 1.2;
			}
		}
	}
}

