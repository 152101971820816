@charset "utf-8";

/* ======================================== */
/* #consultation_index */
/* ======================================== */
#consultation_index {
	.zoom-btn {
		&__google {
			width: 97%;
			margin-top: -28px;
		}
	}
	
	.ui-datepicker {
		width: 300px;
		font-size: $s;
		@include font-normal;
	}
	// header 部分
	.ui-widget-header {
		background: $baseColor;
		color: $white;
	}
	a.ui-datepicker-prev,
	a.ui-datepicker-next {
		cursor: pointer;
	}
	.ui-datepicker-prev,
	.ui-datepicker-next {
		.ui-icon {
			background: none;
			@include transition;
			
			&::before {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				color: $white;
				font-family: 'icomoon';
				font-size: $m;
				text-indent: 0;
				@include centering-elements(true, true);
				@include radius(3);
			}
		}
	}
	.ui-datepicker-prev {
		.ui-icon::before {
			content: "\f104";
		}
	}
	.ui-datepicker-next {
		.ui-icon::before {
			content: "\f105";
		}
	}
	.ui-datepicker-prev-hover,
	.ui-datepicker-next-hover {
		top: 2px;
		border: none;
		background: $white;
		
		.ui-icon {
			background: none;
			&::before {
				color: $baseColor;
			}
		}
	}
	.ui-datepicker-prev-hover {
		left: 2px;
	}
	.ui-datepicker-next-hover {
		right: 2px;
	}	
	// セル部分
	.ui-datepicker td span,
	.ui-datepicker td a {
		padding: .6em;
	}
	.ui-state-default {
		border: 1px solid $borderColor;
		background: $white;
    color: $textColor;
		text-align: center;
		@include font-normal;
		@include radius(2);
		
		&:hover,
		&.ui-state-active {
			background: $baseColor;
			color: $white;
		}
	}	
	span.ui-state-default {
		background: $gray;
	}

}

