@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
	
	&.iframe-html {
		overflow-y: auto;
	}
}

@media screen and (min-width:  767.5px) and (max-width:1024px) {
	html:not(.iframe-html) {
		font-size: calc(100vw / 102.4);
	}
}

.wrap {
	color: $textColor;
	font-size: $l;
	line-height: 1.7;
	overflow: hidden;
	position: relative;
	@include font-normal;
	
	@media screen and (max-width: 1227px) {
		font-size: 1.7rem;
	}
}

.sp-view {
	display: none !important;
}

a {
	color: $baseColor;
	text-decoration: underline;
	
	&:hover {
		text-decoration: none;
	}
}

.link-opacity {
	@include transition;
	
	&:hover {
		@include opacity;
	}
}

a[href^="tel:"] {
	cursor: default;
	color: $textColor;
	text-decoration: none;
	pointer-events: none;
	
	&:hover {
		opacity: 1.0;
	}
}

.icon-filter_none {
	color: $baseColor;
}

/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 150px 0 70px;
	padding: 10px 50px;
	font-size: $xs;
	line-height: 1.4;
	border-bottom: 1px solid $borderColor;
	border-top: 1px dotted $borderColor;
	
	@media screen and (max-width: 1390px) {
		margin: 145px 0 70px;
	}
	
	@media screen and (max-width: 1580px) {
		padding: 10px 25px;
	}
	@media screen and (max-width: 950px) {
		padding: 10px 15px !important;
	}

	&__inner {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				color: #a29d9c;
				font-family: 'icomoon';
			}
		}
		&__link {
			text-decoration: none;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 70px;
	/*bottom: 50px;*/
	z-index: 10;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba($textColor, 0.9);
		color: $white;
		font-size: 4.0rem;
		text-decoration: none;
		@include circle(70);
		@include transition;
		
		@media screen and (max-width: 1400px) {
			@include circle(55);
		}
		
		&:hover {
			@include opacity;
		}
	}
}