@charset "utf-8";
/* ============================================================ */
/* #service_index */
/* ============================================================ */
#service_index {
	.service-img {
		width: 290px;
		height: 220px;
		margin: 0 auto 20px;
	}
	
	.service-text {
	}
	
	.service-visual {
		width: 290px;
		height: 161px;
		margin: 0 auto 20px;
		
		img {
			width: 290px;
			height: 161px;
		}
	}
	
	.common-service {
		&__item {
			&__img {
				width: 290px;
				height: 196px;
				margin: 0 auto 20px;
			}
			
			&__text {
			}
		}
	}
	
	.link-arrow {
		justify-content: flex-end;
	}
	
	.pic-slider {
		&__inner {
			&__item {
				img {
					height: 200px;
					
					@media screen and (min-width: 475px) and (max-width: 845.499px) {
						height: 350px;
					}
				}
			}
		}
	}
	
	.line-img {
		width: 50%;
		border: 1px solid $borderColor;
		margin: 15px auto;
	}
}


