@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {	
	&__inner {
		display: flex;
		max-width: 1320px;
		margin: 0 auto 100px;
		
		@media screen and (max-width: 1420px) {
			margin: 0 50px 100px;
		}
		@media screen and (max-width: 950px) {
			margin: 0 30px 100px !important;
		}
		.main {
			flex: 0 1 900px;
			margin: 0 100px 0 0;
			overflow: hidden;
			@media screen and (min-width:  767.5px) and (max-width:1350px) {
				width: 58vw;
				margin-right: 5.0vw;
			}
		}
		.side {
			flex: 0 1 320px;
 			@media screen and (min-width:  767.5px) and (max-width:1350px) {
				width: 32.6vw;
			}
		}
	}
	&--col1 &__inner {
		display: block;
		max-width: 1320px;
		margin: 0 auto 100px;
		
		@media screen and (max-width: 1420px) {
			margin: 0 50px 100px;
		}
		@media screen and (max-width: 950px) {
			margin: 0 30px 100px !important;
		}
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */
.block {
	margin: 0 0 80px;
	
	&--mb-s {
		margin: 0 0 60px;
	}
	&--mb-xs {
		margin: 0 0 40px;
	}
	&--mb-xxs {
		margin: 0 0 20px;
	}
}

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-bold {
	@include font-bold;
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	margin: 0 0 60px;
	font-size: 3.7rem;
	line-height: 1.2;
	@include font-bold;
	
	@media screen and (max-width: 1295px) {
		font-size: 3.3rem;
	}
	
	&:not(&--mv) {
		padding: 0 0 0 20px;
		border-left: 5px solid $baseColor;
	}
	
	&--pink {
		background: #fff1f6;
		border-left: none !important;
		height: 130px;
		margin-top: -70px;
		padding: 0 50px !important;
		display: flex;
		align-items: center;
	}
	&--pink &__txt {
		max-width: 1320px;
		width: 100%;
		margin: 0 auto;
	}
	
	&--mb-s {
		margin-bottom: 40px;
	}
	
	&--mv {
		display: flex;
		align-items: center;
		height: 230px;
		margin-top: -70px;
		padding: 0 50px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(http://placehold.jp/1920x180.png);
		
		@media screen and (max-width: 1580px) {
			padding: 0 25px;
		}
		@media screen and (max-width: 950px) {
			padding: 0 15px !important;
		}
		.beginner & {
			background-image: url("/common/img/beginner/bg_ttl.jpg");
		}
		#beginner_choose & {
			background-image: url("/common/img/beginner/choose/bg_ttl.jpg");
		}
		#beginner_point & {
			background-image: url("/common/img/beginner/point/bg_ttl.jpg");
		}
		#beginner_eidai & {
			background-image: url("/common/img/beginner/eidai/bg_ttl.jpg");
		}
		#beginner_kaisou & {
			background-image: url("/common/img/beginner/kaisou/bg_ttl.jpg");
		}
		#beginner_flow & {
			background-image: url("/common/img/beginner/flow/bg_ttl.jpg");
		}
		#beginner_group & {
			background-image: url("/common/img/beginner/group/bg_ttl.jpg");
		}
		#beginner_movie & {
			background-image: url("/common/img/beginner/movie/bg_ttl.jpg");
			background-position: center right;
		}
		.kukaku & {
			background-image: url("/common/img/kukaku/bg_ttl.jpg");
		}
		#kukaku_ippan & {
			background-image: url("/common/img/kukaku/ippan/bg_ttl.jpg");
		}
		#kukaku_roman & {
			background-image: url("/common/img/kukaku/roman/bg_ttl.jpg");
		}
		#kukaku_kuyou_index & {
			background-image: url("/common/img/kukaku/kuyou/bg_ttl.jpg");
		}
		#kukaku_jumoku_index & {
			background-image: url("/common/img/kukaku/jumoku/bg_ttl.jpg");
		}
		#kukaku_viola & {
			background-image: url("/common/img/kukaku/viola/bg_ttl.jpg");
		}
		#kukaku_green-load & {
			background-image: url("/common/img/kukaku/green-load/bg_ttl.jpg");
		}
		#kukaku_canna & {
			background-image: url("/common/img/kukaku/canna/bg_ttl.jpg");
		}
		#kukaku_mimosa & {
			background-image: url("/common/img/kukaku/mimosa/bg_ttl.jpg");
		}
		#kukaku_poplar & {
			background-image: url("/common/img/kukaku/poplar/bg_ttl.jpg");
		}
		#kukaku_sion & {
			background-image: url("/common/img/kukaku/sion/bg_ttl.jpg");
		}
		#kukaku_mint & {
			background-image: url("/common/img/kukaku/mint/bg_ttl.jpg");
		}
		#kukaku_set & {
			background-image: url("/common/img/kukaku/set/bg_ttl.jpg");
		}
		#kukaku_kuyou_one & {
			background-image: url("/common/img/kukaku/kuyou/one/bg_ttl.jpg");
		}
		#kukaku_kuyou_two & {
			background-image: url("/common/img/kukaku/kuyou/two/bg_ttl.jpg");
		}
		#kukaku_kuyou_three & {
			background-image: url("/common/img/kukaku/kuyou/three/bg_ttl.jpg");
		}
		#kukaku_kuyou_four & {
			background-image: url("/common/img/kukaku/kuyou/four/bg_ttl.jpg");
		}
		#kukaku_jumoku_one & {
			background-image: url("/common/img/kukaku/jumoku/one/bg_ttl.jpg");
		}
		#kukaku_jumoku_woman & {
			background-image: url("/common/img/kukaku/jumoku/woman/bg_ttl.jpg");
		}
		#kukaku_jumoku_two & {
			background-image: url("/common/img/kukaku/jumoku/two/bg_ttl.jpg");
		}
		#kukaku_gassou & {
			background-image: url("/common/img/kukaku/gassou/bg_ttl.jpg");
		}
		#kukaku_pet_index & {
			background-image: url("/common/img/kukaku/pet/bg_ttl.jpg");
		}
		#kukaku_pet_senyou & {
			background-image: url("/common/img/kukaku/pet/senyou/bg_ttl.jpg");
		}
		#kukaku_pet_together & {
			background-image: url("/common/img/kukaku/pet/together/bg_ttl.jpg");
		}
		.hoyo & {
			background-image: url("/common/img/hoyo/bg_ttl.jpg");
		}
		#hoyo_kandou & {
			background-image: url("/common/img/hoyo/kandou/bg_ttl.jpg");
		}
		#hoyo_henreihin & {
			background-image: url("/common/img/hoyo/henreihin/bg_ttl.jpg");
		}
		#hoyo_kaisyoku & {
			background-image: url("/common/img/hoyo/kaisyoku/bg_ttl.jpg");
		}
		#hoyo_yasuragi & {
			background-image: url("/common/img/hoyo/yasuragi/bg_ttl.jpg");
		}
		#hoyo_kutsurogi & {
			background-image: url("/common/img/hoyo/kutsurogi/bg_ttl.jpg");
		}
		#hoyo_hohoemi & {
			background-image: url("/common/img/hoyo/hohoemi/bg_ttl.jpg");
		}
		.service & {
			background-image: url("/common/img/service/bg_ttl.jpg");
		}
		.customer & {
			background-image: url("/common/img/customer/bg_ttl.jpg");
		}
		.reservation & {
			background-image: url("/common/img/reservation/bg_ttl.jpg");
		}
	}
	&--mv &__txt {
		max-width: 1320px;
		width: 100%;
		margin: 0 auto;
		color: $white;
		text-shadow: 0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5);
	}
}

.ttl02 {
	position: relative;
	margin: 0 0 30px;
	font-size: 2.7rem;
	line-height: 1.3;
	color: $textColor;
	@include font-bold;
	
	@media screen and (max-width: 1295px) {
		font-size: 2.6rem;
	}
	
	&::before {
		display: block;
		color: $baseColor;
		@include font-poppins;
		font-size: $l;
		text-transform: uppercase;
		content: attr(data-text);
		margin: 0 0 5px;
		font-weight: normal;
	}
	
	&--icon {
		padding: 24px 0 0 50px;
		border-top: 1px solid $borderColor;
		
		_:-ms-fullscreen, :root & {
			padding: 26px 0 0 50px;
		}
		
		&::before {
			top: 26px;
			left: 0;
			position: absolute;
			content: '';
			width: 30px;
			height: 30px;
			margin: 0;
			background: url("/common/img/common/ico_ttl.svg") 0 0 no-repeat;
			font-size: 4.0rem;
		}
		
		&::after {
			position: absolute;
			content: " ";
			display: block;
			border-bottom: 4px solid #ff8db4;
			top: -1px;
			left: 0;
			width: 40%;
		}
	}
	
	&--pink {
		border: 1px solid #F8DCE5;
		border-top: 4px solid $baseColor;
		background: #FFF6F9;
		padding: 8px 20px 13px;
		border-radius: 0 0 3px 3px;
	}
	&--fz-37 {
		font-size: 3.7rem;
		margin: 0 0 60px;
		&.ttl02--icon {
			padding: 24px 0 0 70px;
			&::before {
				width: 45px;
				height: 45px;
			}
		}
	}
}

.ttl03 {
	position: relative;
	margin: 0 0 30px;
	font-size: 2.5rem;
	@include font-bold;
	
	@media screen and (max-width: 1160px) {
		font-size: $xxl;
	}
	
	&:not(&--line) {
		color: $baseColor;
		line-height: 1.4;
		text-align: center;
	}
	&--line {
		padding: 10px 15px;
		border-top: 3px solid $baseColor;
		border-bottom: 1px solid $borderColor;
		background: $white;
	}
	&--noline {
		color: $textColor !important;
		text-align: left !important;
		font-size: 2.3rem;
		margin: 0 0 25px !important;
		
		&::after {
			display: none;
		}
	}
	&--icon {
		font-size: 2.9rem;
		padding: 95px 0 0 0;
		&::before {
			top: 0;
			@include centering-elements(true, false);
			position: absolute;
			content: '';
			width: 115px;
			height: 68px;
			margin: 0;
			background: url("/common/img/common/ico_ttl03.svg") 0 0 no-repeat;
		}
	}
}

.ttl04 {
	margin: 0 0 20px;
	padding: 10px 10px 10px 40px;
	background: #FFEEF3;
	@include font-bold;
	line-height: 1.4;
	@include radius(3);
	font-size: $xxl;
	position: relative;
	
	&::before {
		position: absolute;
		top: 50%;
		left: 0;
		content: '';
		height: 3px;
		width: 25px;
		background: $baseColor;
	}
	
	&--pink {
		color: $baseColor;
	}
}

.ttl05 {
	margin: 0 0 20px;
	padding: 0 0 15px;
	border-bottom: 1px solid $baseColor;
	font-size: $l;
	@include font-bold;
	line-height: 1.4;
	color: $baseColor;
	
	&--fz-xxl {
		font-size: $xxl;
	}
}

.ttl06 {
	margin: 0 0 15px;
	padding: 0 0 0 35px;
	font-size: 1.7rem;
	@include font-bold;
	line-height: 1.4;
	position: relative;
	
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: '';
		width: 24px;
		height: 24px;
		background: url("/common/img/common/ico_ttl05.svg") 0 0 no-repeat;
	}
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -20px;
	 margin-right: -20px;

	 &> * {
		 width: calc((100% - 81px) / 2);
		 margin-left: 20px;
		 margin-right: 20px;
	 }
 }

.column3 {
	margin-left: -20px;
	margin-right: -20px;
	
	@media screen and (max-width: 1150px) {
		margin-left: -15px;
		margin-right: -15px;
	}
	&> * {
		margin-left: 20px;
		margin-right: 20px;
		width: calc((100% - 121px) / 3);
		
		@media screen and (max-width: 1150px) {
			margin-left: 15px;
			margin-right: 15px;
			width: calc((100% - 91px) / 3);
		}
	}
}

.column4 {
	margin-left: -15px;
	margin-right: -15px;

	&> * {
		margin-left: 15px;
		margin-right: 15px;
		width: calc((100% - 121px) / 4);
	}
}

.column5 {
	margin-left: -5px;
	margin-right: -5px;

	&> * {
		margin-left: 5px;
		margin-right: 5px;
		width: calc((100% - 51px) / 5);
	}
}

.column6 {
	margin-left: -5px;
	margin-right: -5px;

	&> * {
		margin-left: 5px;
		margin-right: 5px;
		width: calc((100% - 61px) / 6);
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 10px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--brown {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--pink {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px 30px;
	background: $white;
	@include radius(3);

	@media screen and (max-width: 1080px) {
		padding: 20px 25px;
	}
	
	&--pink {
		background: $lightColor;
	}
	&--gray {
		background: $gray;
	}
	&--yellow {
		background: #fefaef;
		border: 1px solid #e9e4d4;
	}
	&--border-pink {
		border: 3px solid $baseColor;
	}
	&--border-lightpink {
		border: 3px solid $lightColor;
	}
	&--border-gray {
		border: 3px solid $borderColor;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* panel */
/* --------------------------------------------------- */
.panel {
	border: 1px solid $borderColor;
	
	&__ttl {
		padding: 20px 30px;
		border-bottom: 1px dotted $borderColor;
		text-align: center;
	}
	&__body {
		padding: 25px 30px;
	}
	&--flow {
		position: relative;
		margin: 0 0 50px;
		
		&:after {
			position: absolute;
			bottom: -40px;
			left: calc(50% - 25px);
			width: 0;
			height: 0;
			border-color: $baseColor transparent transparent;
			border-style: solid;
			border-width: 25px 25px 0;
			content: "";
		}
		&:last-of-type {
			margin-bottom: 0;
			
			&::after {
				display: none;
			}
		}
	}
	&--flow &__body {
		padding: 30px;
	}
}



/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 13px 15px;
		background: $white;
		border: 1px solid $borderColor;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
		font-size: 1.7rem;
	}
	.bg-pink {
		background: $baseColor;
		color: $white;
	}
	.bg-pink-light {
		background: $lightColor;
	}
	.bg-beige {
		background: #f4f1ea;
	}
	.bg-white {
		background: $white;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}

.table-scroll {
	&__txt {
		display: none;
	}
}




/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 250px;
	height: 65px;
	padding: 0 25px;
	font-size: $m;
	text-align: center;
	text-decoration: none;
	line-height: 1.3;
	cursor: pointer;
	transition: 0.3s all;
	@include radius(3);
	@include font-bold;
		
	@media screen and (max-width: 1245px) {
		height: 55px;
		font-size: 1.5rem !important;
	}
	
	.icon-filter_none {
		color: $white;
	}
	
	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 10px; }
	.icon-fix-right { right: 10px; }
	
	@media screen and (max-width: 1150px) {
		height: 70px !important;

		.icon-fix-left { left: 7px !important; }
		.icon-fix-right { right: 7px !important; }
	}
	
	&:hover {
		text-decoration: none;
		
		.icon-filter_none {
			color: $baseColor;
		}
	}
	
	&--pink {
		background: $baseColor;
		border: 1px solid $baseColor;
		color: $white;
		
		&:hover {
			background: $white;
			border: 1px solid $baseColor;
			color: $baseColor;
		}
	}
	
	&--brown {
		background: $brown;
		border: 1px solid $brown;
		color: $white;
		
		&:hover {
			background: $white;
			border: 1px solid $brown;
			color: $brown;
		}
	}
	
	&--border {
		border: 1px solid $borderColorBeige;
		background: $white;
		color: $textColor;
		
		.icon-fix-right {
			color: $borderColorBeige;
		}
		
		&:hover {
			background: $borderColorBeige;
			
			.icon-fix-right {
				color: $textColor;
			}
		}
	}
	
	&--border-gray {
		border: 1px solid $borderColor;
		background: $white;
		color: $textColor;
		
		.icon-fix-right {
			color: $baseColor;
		}
		
		&:hover {
			background: $borderColor;
			
			.icon-fix-right {
				color: $textColor;
			}
		}
	}
	
	&--lg {
		min-width: 500px;
		height: 65px;
		font-size: $m;
	}
	&--sm {
		width: 40%;
		height: 50px;
		padding: 0 10px;
		font-size: $l;
		min-width: inherit;
	}
	&--support {
		min-width: 350px;
		height: 75px;
		font-size: $xl;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}

.btn-block {
	display: flex;
	align-items: center;
	justify-content: center;
	
	& > * {
		margin: 0 10px;
	}
}

/* --------------------------------------------------- */
/* ico-arrow */
/* --------------------------------------------------- */
.ico-arrow {
	border: 1px solid $borderColor;
	color: $baseColor;
	background: $white;
	@include circle(50);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: $xxxl;
	line-height: 1.2;
	
	&--sm {
		@include circle(34);
		font-size: $l;
		
		@media screen and (max-width: 910px) {
			@include circle(25);
		}
	}
}

a:hover {
	.ico-arrow {
		border: 1px solid $baseColor;
		color: $white;
		background: $baseColor;
		@include transition;
	}	
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	line-height: 1.4;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			min-width: 45px;
			padding: 10px;
			background: $white;
			border: 1px solid $borderColor;
			color: $textColor;
			text-align: center;
			text-decoration: none;
			@include transition;
			@include radius(3);
			
			&.current,
			&:hover {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* ank-link */
/* --------------------------------------------------- */
.ank-link {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	border-left: 1px solid #f3d2dd;
	
	&__item {
		width: calc((100% - 1px) / 3);
		border: 1px solid #f3d2dd;
		border-bottom: none;
		border-left: none;
		
		&:last-of-type ,
		&:nth-last-of-type(2) ,
		&:nth-last-of-type(3) {
			border-bottom: 1px solid #f3d2dd;
		}
						
		&__link {
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			text-align: center;
			font-size: $m;
			color: $textColor;
			padding: 15px 20px;
			width: 100%;
			/*height: 60px;*/
			position: relative;
			background: $lightColor;
			transition: 0.3s all;
			line-height: 1.3;
			
			.icon-fix-left,
			.icon-fix-right {
				@include centering-elements(false, true);
				color: $baseColor;
			}
			.icon-fix-left { left: 15px; }
			.icon-fix-right { right: 15px; }

			@media screen and (max-width: 1150px) {
				/*height: 70px !important;*/

				.icon-fix-left { left: 7px; }
				.icon-fix-right { right: 7px; }
			}
			
			&:hover {
				background: $baseColor;
				color: $white;
				
				.icon-fix-left,
				.icon-fix-right {
					color: $white;
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 12px 40px 12px 20px;
	background: #f4f1ea;
	cursor: pointer;
	line-height: 1.6;
	@include font-bold;
	font-size: 1.9rem;
	
	_:-ms-fullscreen, :root & {
		padding: 15px 40px 10px 20px;
	}
	
	&__icon {
		&::before {
			content: "\e90d";
			font-family: 'icomoon';
			display: block;
			line-height: 1.0;
			color: $baseColor;
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translate(0, -50%);
			font-size: $xxl;
		}
	}
	&.active {
		.accordion__icon {
			&::before {
				content: "\e90e";
			}
		}
	}
}
.accordion-hide {
	display: none;
}

.sp-accordion {
	.accordion__icon {
		display: none;
	}
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.txt-error {
	margin: 5px 0 0;
	color: #dd0000;
	font-size: $m;
	line-height: 1.4;
	@include font-bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid $borderColor;
	color: $textColor;
	font-size: $m;
	@include font-normal;
	@include radius(0);
	
	&.w-s {
		max-width: 150px;
		width: 100%;
	}
	&.w-m {
		max-width: 400px;
		width: 100%;
	}
	&.w-l {
		width: 100%;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid $borderColor;
	color: $textColor;
	@include font-normal;
	@include radius(0);
}

input::placeholder,
textarea::placeholder {
	color: rgba($textColor, .4);
	opacity: 1;
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid $borderColor;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: $white;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	display: inline-block;
	text-align: center;
	padding: 3px 10px;
	min-width: 100px;
	box-sizing: border-box;
	color: $white;
	font-size: $xs;
	@include radius(3);
	font-weight: normal;
	
	&--info,
	&--orange {
		background: $orange;
	}
	
	&--cat41,
	&--mint {
		background: $mint;
	}
	
	&--pink ,
	&--cons {
		background: $baseColor;
	}

	&--event {
		background: #619816;
	}

	&--memorial {
		background: #9c66c2;
	}

	&--blog {
		background: #4dabfa;
	}

	&--cat32 {
		background: #854718;
	}

	&--cat42 {
		background: #067e55;
	}

	&--link {
		text-decoration: none;
		&:hover {
			text-decoration: none;
			@include opacity;
			@include transition;
		}
	}
}



/* --------------------------------------------------- */
/* block-contact */
/* --------------------------------------------------- */
.block-contact {
	position: relative;
	background: #FFF8FA;
	margin: -6px 0 0;
	padding: 80px 0;
	
	&::after {
		display: inline-block;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 238px;
    background: url("/common/img/common/bg_contents_bottom.jpg") center bottom no-repeat;
    background-size: 100%;
    content: "";
	}
	
	&__ttl {
		text-align: center;
		position: relative;
		z-index: 2;
	}
	
	&__inner {
		max-width: 1560px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 2;
				
		@media screen and (max-width: 1230px) {
			margin: 0 15px;
		}
		
		&__item {
			width: calc((100% - 121px) / 3);
			margin: 0 20px;
			
			@media screen and (max-width: 1230px) {
				width: calc((100% - 61px) / 3);
				margin: 0 10px;
			}
			
			&__link {
				background: url("/common/img/common/bg_bnr.png") 0 0 repeat;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include radius(3);
				color: $white;
				padding: 15px 25px;
				box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
				text-decoration: none;
				
				&__img {
					width: 100px;
					
					@media screen and (max-width: 1230px) {
						width: 70px;
					}
				}
				
				&__text {
					width: calc(100% - 115px);
					
					@media screen and (max-width: 1230px) {
						width: calc(100% - 85px);
					}
					
					&__ttl {
						font-size: $xxl;
						@include font-bold;
						line-height: 1.4;
						
						@media screen and (max-width: 1230px) {
							font-size: $xl;
						}
					}
					
					&__sub {
						font-size: $l;
						color: #FFF5CF;
						line-height: 1.4;
						
						@media screen and (max-width: 1450px) {
							font-size: 1.5rem;
						}
					}
				}
			}
		}
	}
}



/* --------------------------------------------------- */
/* .beginner-bnr */
/* --------------------------------------------------- */
.beginner-bnr {
	max-width: 1320px;
	margin: 0 auto 50px;
	
	@media screen and (max-width: 1420px) {
		margin: 0 50px 50px;
	}
	@media screen and (max-width: 950px) {
		margin: 0 30px 50px !important;
	}
	
	&__inner {
		display: flex;
		justify-content: space-between;
		margin: 0 -20px;

		@media screen and (max-width: 1230px) {
			margin: 0 -10px;
		}
		
		&__item {
			width: calc((100% - 121px) / 3);
			margin: 0 20px;
			
			@media screen and (max-width: 1230px) {
				width: calc((100% - 61px) / 3);
				margin: 0 10px;
			}
			
			&__link {
				background: url("/common/img/common/bg_bnr.png") 0 0 repeat;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include radius(3);
				color: $white;
				padding: 15px 25px;
				box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
				text-decoration: none;
				
				&__img {
					width: 100px;
					
					@media screen and (max-width: 1230px) {
						width: 70px;
					}
				}
				
				&__text {
					width: calc(100% - 115px);
					
					@media screen and (max-width: 1230px) {
						width: calc(100% - 85px);
					}
					
					&__ttl {
						font-size: $xxl;
						@include font-bold;
						line-height: 1.4;
					}
					
					&__sub {
						font-size: $l;
						color: #FFF5CF;
						line-height: 1.4;
					}
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* link-list */
/* --------------------------------------------------- */
.link-list {
	display: flex;
	flex-wrap: wrap;
	margin: 40px -10px 0;
		
	&--column02 {
		margin: 40px -15px 0;
	
		@media screen and (max-width: 1280px) {
		}
		
		.link-list {
			&__item {
				width: calc((100% - 61px) / 2);
				margin: 0 15px 30px;
				
				@media screen and (max-width: 1280px) {
				}

				@media screen and (max-width: 1100px) {
				}
				
				&__img {
					padding: 25px 25px 0;
					margin: 0 0 20px;
				}

				&__text {
					padding: 0 25px 35px;
				}
			}
		}
	}
	
	&__item {
		width: calc((100% - 61px) / 3);
		margin: 0 10px 20px;
		border: 1px solid #D9D9D9;
		@include radius(3);
		
		@media screen and (max-width: 1100px) {
			width: calc((100% - 41px) / 2);
		}
				
		&__link {
			text-decoration: none;
			color: $textColor;
			@include transition;
			display: block;
			
			&--no {
				&:hover {
					.link-list {
						&__item {
							&__link {
								&__img {
									opacity: 1;
								}
							}
						}
					}
				}
			}
			
			&:hover {
				@include opacity;
			}
			
			&__ttl {
				padding: 15px 20px;
				border-bottom: 1px dotted #D9D9D9;
				text-align: center;
				@include font-bold;
				font-size: $xxl;
				color: $baseColor;
				background: #FFF6F9;
				line-height: 1.3;
				
				@media screen and (max-width: 1280px) {
					font-size: 1.9rem;
				}
				
				&__inner {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					
					&__text {
						max-width: calc(100% - 32px);
						text-align: left;
					}
				}
				
				.ico-arrow {
					margin: -3px 10px 0 0;
					width: 22px;
					height: 22px;
					background: $baseColor;
					color: $white;
					border: 1px solid $baseColor;
					font-size: $s;
				}
			}
			
			&__img {
				padding: 20px 20px 0;
				margin: 0 0 15px;
			}

			&__text {
				padding: 0 20px 30px;
				font-size: $m;
			}
		}
	}
}



/* --------------------------------------------------- */
/* common-service */
/* --------------------------------------------------- */
.common-service {
	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 30px;
		padding: 0 0 30px;
		border-bottom: 1px dotted $borderColor;
		
		&__img {
			width: 30%;
		}
		
		&__text {
			width: 67%;
		}
	}
}


/* ============================================================ */
/* .common-btn */
/* ============================================================ */
.common-btn {
	margin: 40px 0 0;

	&__inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin: 0 -0.5%;
		width: 100%;
		
		&--column4 {
			.common-btn {
				&__inner {
					&__item {
						width: calc((100% - 4%) / 4);
					}
				}
			}
		}
		
		&__item {
			width: calc((100% - 3%) / 3);
			margin: 0 0.5% 15px;
		}
	}

	.btn {
		width: 100%;
		height: 100%;
		min-width: auto !important;
		padding-top: 5px;
		padding-bottom: 5px;
		min-height: 65px;
		
		/*@media screen and (max-width: 1080px) {
			br {
				display: none !important;
			}
		}*/
		
		/*@media screen and (max-width: 1080px) {
			br {
				display: none !important;
			}
		}*/
		
		@media screen and (max-width: 1305px) {
			padding: 0 12px;
			
			.icon-fix-left { left: 7px; }
			.icon-fix-right { right: 7px; }
		}
	}
}


/* ============================================================ */
/* .common-bnr */
/* ============================================================ */
.common-bnr {
	max-width: 1560px;
	margin: 30px auto 0;
	
	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
				
		&__item {
			margin: 0 10px;
			width: 17%;
			max-width: 260px;
		}
	}
}


/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	max-width: 1320px;
	margin: 0 auto 70px;

	@media screen and (max-width: 1420px) {
		margin: 0 50px 70px;
	}
	@media screen and (max-width: 950px) {
		margin: 0 30px 70px !important;
	}
	
	&__ttl {
		padding: 0 0 0 20px;
		margin: 0 0 20px;
		border-left: 3px solid $baseColor;
		font-size: $xxxl;
		color: $textColor;
		@include font-bold;
	}
	
	&__ttl02 {
		.ico-arrow {
			@include circle(25);
			font-size: 1.5rem;
			line-height: 1.0;
			margin: 0 10px 0 0;
		}
		
		&__link {
			text-decoration: none;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	.ttl05 {
		font-size: $xl;
	}
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px 30px;
		
		&:last-of-type {
			margin: 0 -10px;
		}
		
		&__item {
			width: calc((100% - 81px) / 4);
			margin: 0 10px 15px;
			
			@media screen and (max-width: 1180px) {
				width: calc((100% - 61px) / 3);
			}
			
			&__link {
				border: 1px solid $borderColor;
				background: $white;
				@include radius(3);
				padding: 12px 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-decoration: none;
				@include transition;
				color: $textColor;
				
				&::after {
					content: "\e901";
					color: $baseColor;
					font-family: 'icomoon';
				}
				
				&.current ,
				&:hover {
					background: $lightColor;
					@include opacity;
					opacity: 1;
					color: $baseColor;
					@include font-bold;
				}
				
				&__img {
					width: 70px;
					margin: 0 10px 0 0;
					@include radius(3);
					
					img {
						@include radius(3);
					}
				}
				
				&__text {
					width: 68%;
					line-height: 1.4;
					font-size: 1.5rem;
					display: flex;
					align-items: center;
					
					&__sub {
						/*@media screen and (max-width: 1525px) {
							br {
								display: none;
							}
						}*/
					}
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	&__item {
		margin-bottom: 40px;
		
		&__link {
			display: block;
			text-decoration: none;
			color: $textColor;

			&:hover &__img {
				@include opacity;
			}
			&:hover &__ttl {
				text-decoration: none;
			}
			&__img {
				position: relative;
				width: 100%;
				height: 170px;
				margin: 0 0 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				border: 1px solid $borderColor;
				@include transition;
				
				&__inner {
					width: auto;
				}
			}
			&__head {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 0 0 10px;
				
				@media screen and (max-width: 1220px) {
					display: block;
					margin: 0 0 5px;
					
					&__category {
						margin: 0 0 3px;
					}
					
					&__date {
						font-size: $s;
						margin: 0;
					}
				}
				
				&__date {
					font-size: 1.5rem;
					margin: 0;
				}
			}
			&__ttl {
				margin: 0 0 10px;
				text-decoration: underline;
				color: $baseColor;
				position: relative;
				max-height: calc(17 * 1.5 * 2 * 1px);
				line-height: 1.6;
				word-break: break-all;
				overflow: hidden;
				font-size: 1.7rem;

				&::before,
				&::after {
					position: absolute;
					background: $white;
				}
				&::before {
					content: '...';
					top: calc(17 * 1.5 * (2 - 1) * 1px);
					right: 0;
					width: 2.0em;
				}
				&::after {
					content: '';
					width: 100%;
					height: 100%;
				}
			}
			&__label {
				margin: 0 0 5px;
			}
		}
	}
}


/* --------------------------------------------------- */
/* customer-list */
/* --------------------------------------------------- */
.customer-list {
	&__item {
		margin-bottom: 40px;
		
		&__link {
			display: block;
			text-decoration: none;
			color: $textColor;

			&:hover &__img {
				@include opacity;
			}
			&:hover &__ttl {
				text-decoration: none;
			}
			&__img {
				position: relative;
				width: 100%;
				height: 270px;
				margin: 0 0 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				border: 1px solid $borderColor;
				@include transition;
				
				&__inner {
					width: auto;
				}
			}
			&__head {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
			}
			&__category {
				display: flex;
				justify-content: flex-end;
			}
			&__label {
				align-self: flex-start;
				margin-left: 5px;
				display: block;
			}
			&__date {
				@include font-bold;
			}
			&__ttl {
				margin: 5px 0 0;
				text-decoration: underline;
				color: $baseColor;
				word-break: break-all;
				font-size: 1.6rem;
			}
		}
	}
}


/* --------------------------------------------------- */
/* blog-bnr */
/* --------------------------------------------------- */
.blog-bnr {
	margin: 0 0 30px;
	max-width: 720px;
	
	&__tree {
		display: block;
	}
}


/* --------------------------------------------------- */
/* link-arrow */
/* --------------------------------------------------- */
.link-arrow {
	display: flex;
	
	&__ico {
		flex: 0 0 auto;
		margin: 5px 8px 0 5px;
		color: $baseColor;
	}
	&__link {
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
}


/* --------------------------------------------------- */
/* contact-box */
/* --------------------------------------------------- */
.contact-box {
	max-width: 600px;
	margin: 0 auto;
	padding: 25px 30px;
	background: $lightColor;
	text-align: center;
	line-height: 1.4;
	@include radius(3);
	
	&__ttl {
		margin: 0 0 10px;
		@include font-bold;
	}
	&__phone {
		font-size: 3.2rem;
		@include font-bold;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0 10px;
		
		&__ico {
			margin: 0 10px 0 0;
			color: $freecall;
		}
		&__link {
			color: $baseColor !important;
		}
	}
}




/* --------------------------------------------------- */
/* pic-slider */
/* --------------------------------------------------- */
.pic-slider {
	width: 100%;
	height: 500px;
	overflow: hidden;
	max-width: 900px;
	margin-right: auto;
	margin-left: auto;

	&__inner {
		height: 500px;
		position: relative;

		&__item {
			height: 500px;

			.object-fit-img {
				height: 500px;
				object-fit: cover;
				object-position: center center;
				font-family: 'object-fit: cover; object-position: center center;'
			}
		}

		&__slider {
			height: 500px;
			overflow: hidden;
			position: relative;
		}
	}
	.slick-prev,
	.slick-next {
		position: absolute;
		top: 50%;
		z-index: 50;
		transform: translate(0, -50%);
	}
	
	.slick-prev {
		left: 20px;
	}
	
	.slick-next {
		right: 20px;
	}
	
	.slick-list ,
	.slick-track {
		height: 500px;
	}
	
	.slick-dots {
		margin: -50px 0 0;
		position: relative;
		z-index: 2;
	}
}

/* --------------------------------------------------- */
/* pic-slider02 */
/* --------------------------------------------------- */
.pic-slider02 {
	&-main {
		&__item {
			position: relative;
			overflow: hidden;

			.__caption {
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
				padding: 10px 15px;
				background: rgba(#000, .35);
				color: $white;
				font-size: $m;
			}
		}
	}
	&-thumb {
		position: relative;
		margin: 20px -10px 0;

		&__item {
		}
		
		.slick-list {
		}
		.slick-slide {
			padding: 0 10px;
			cursor: pointer;
		}
		.slick-prev,
		.slick-next {
			z-index: 1;
			width: 24px;
			height: 24px;
			border-radius: 0;
			border: none;
			background: $baseColor;
			@include centering-elements(false, true);

			&::before {
				color: $white;
				opacity: 1;
				font-size: $l;
			}
		}
		.slick-prev {
			left: 10px;
		}
		.slick-next {
			right: 10px;
		}
	}	
	
}


/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	width: 100%;
	margin: 0 0 50px;
	
	&__ttl {
		&__link {
			display: flex;
			align-items: center;
			padding: 20px;
			background: url("/common/img/common/bg_snav.png") 0 0 repeat;
			color: $textColor;
			font-size: $l;
			text-decoration: none;
			line-height: 1.4;
			@include radius(3);
			@include font-bold;
			@include transition;
			
			@media screen and (max-width: 1580px) {
				padding: 15px;
			}
			&::before  {
				flex: 0 0 auto;
				content: '';
				width: 30px;
				height: 25px;
				margin: 0 15px 0 0;
				background: url("/common/img/common/ico_snav.svg") 0 0 no-repeat;
			}
		}
		a.snav__ttl__link:hover {
			@include opacity;
		}
	}
	&__item {
		display: flex;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid $borderColor;
		color: $textColor;
		text-decoration: none;
		@include transition;
		font-size: 1.7rem;
		line-height: 1.5;
		
		@media screen and (max-width: 1227px) {
			font-size: 1.5rem;
		}
				
		&__txt {
			flex: 1 1 auto;
		}
		.icon-angle-right {
			flex: 0 0 auto;
			margin: 0 15px 0 0;
			color: $baseColor;
		}
		&.current ,
		&:hover {
			color: $baseColor;
		}
	}
	&__inner {
		&__sub {
			&__item {
				display: flex;
				align-items: center;
				padding: 15px 40px;
				color: $textColor;
				text-decoration: none;
				@include transition;
				line-height: 1.5;
				border-bottom: 1px dotted $borderColor;
				font-size: 1.7rem;

				@media screen and (max-width: 1227px) {
					font-size: 1.5rem;
				}
				
				&:last-of-type {
					border-bottom: 1px solid $borderColor;		
				}
				
				.icon-angle-right {
					flex: 0 0 auto;
					margin: 0 10px 0 0;
					color: $baseColor;
				}
				
				&.current ,
				&:hover {
					color: $baseColor;
				}
			}
		}
		
		&__month {
			font-size: 1.7rem;
			
			&__text {
				padding: 20px;
				border-bottom: 1px solid $borderColor;
				display: block;
				position: relative;
				cursor: pointer;
				
				&__ico {
					&::before {
						content: "\e90d";
						font-family: 'icomoon';
						display: block;
						line-height: 1.0;
						color: $baseColor;
						position: absolute;
						top: 50%;
						right: 15px;
						transform: translate(0, -50%);
						font-size: $xxl;
					}
				}
				&.active {
					.snav__inner__month__text__ico {
						&::before {
							content: "\e90e";
						}
					}
				}
			}
			
			&__list {
				display: none;
				
				&__item {
					&:last-of-type {
						.snav {
							&__item {
								border-bottom: 1px solid $borderColor;
							}
						}
					}
					
					.snav {
						&__item {
							padding: 15px 40px;
						}
					}
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* side_bnr */
/* --------------------------------------------------- */
.side_bnr {
	&__item {
		margin: 0 0 15px;
		
		&__link {
			padding: 20px;
			border: 1px solid $borderColor;
			color: $textColor;
			font-size: 1.9rem;
			text-decoration: none;
			line-height: 1.4;
			@include radius(3);
			@include transition;
			display: flex;
			@include font-bold;
			align-items: center;
			
			&__ico {
				color: $baseColor;
				font-size: $xxxl;
				margin: 0 10px 0 0;
			}
			
			&:hover {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				
				.side_bnr {
					&__item {
						&__link {
							&__ico {
								color: $white;
							}
						}
					}
				}
			}
		}
	}
}

