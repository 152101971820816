@charset "utf-8";
/* ============================================================ */
/* /news/ */
/* ============================================================ */
.news {
	&__sns {
		display: flex;
		align-items: center;
		padding: 10px 15px 5px;
		background: $gray;
		
		&__fb {
			margin: 0 10px 0 0;
		}
	}
	.entry__ttl + .news__sns {
		margin-bottom: 25px;
	}
	.entry__body + .news__sns {
		margin-top: 25px;
	}
			
	.fb-page {
		width: 100%;

		* {
			width: 100% !important;
		}
	}
	
	.entry {
		&__ttl {
			margin: 0 0 20px;

			&__inner {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			&__date {
				font-size: $s;
			}
		}
		&__body {
			img {
				max-width: 100% !important;
				height: auto !important;
			}
			
			.instagram-media {
				width: 414px !important;
				max-width: 100% !important;
				min-width: initial !important;
			}
			
			span[style="color: #3366ff;"] {
				a {
					color: #3366ff;
				}
			}
			
			span[style="color: #0000ff;"] {
				a {
					color: #0000ff;
				}
			}
			
			span[style="color: #ff00ff;"] {
				a {
					color: #ff00ff;
				}
			}
						
			iframe {
				width: 100%;
			}
			
			.facebook-block {
				iframe {
					height: 850px !important;
				}
			}
			
			.sns-inner {
				font-family: initial !important;
				
				.fb-page {
					width: 100%;

					* {
						width: 100% !important;
					}
				}
			}
			
			table {
				width: 100%;
				border: 1px solid $borderColor;
				line-height: 1.6;
				word-break: break-all;
				word-wrap: break-word;

				th,
				td {
					padding: 10px;
					background: $white;
					border: 1px solid $borderColor;
					text-align: left;
					vertical-align: middle;
				}
				
				th {
					background: $lightColor;			
				}
				
				.bg-pink {
					background: $baseColor;
					color: $white;
				}
				.bg-pink-light {
					background: $lightColor;
				}
				.bg-beige {
					background: #f4f1ea;
				}
				.bg-white {
					background: $white;
				}
				.error {
					background: #fde9e9;
					color: #dd0000;
				}
			}
			
			.midashi03 {
				position: relative;
				margin: 0 0 25px;
				padding: 15px 0 0;
				font-size: $l;
				line-height: 1.4;
				@include font-bold;

				&::before,
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					height: 2px;
				}
				&::before {
					width: 100%;
					background: $borderColor;
				}
				&::after {
					width: 30%;
					background: $baseColor;
				}
			}
			.midashi04 {
				margin: 0 0 15px;
				padding: 0 0 10px;
				border-bottom: 1px solid $baseColor;
				font-size: $l;
				@include font-bold;
				line-height: 1.4;
			}
			.midashi05 {
				position: relative;
				margin: 0 0 15px;
				padding: 0 0 0 30px;
				font-size: $m;
				@include font-bold;
				line-height: 1.4;

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					width: 24px;
					height: 24px;
					background: url("/common/img/common/ico_ttl05.svg") 0 0 no-repeat;
				}
			}
			.midashi06 {
				margin: 0 0 15px;
				color: $baseColor;
				font-size: $m;
				@include font-bold;
				line-height: 1.4;
			}
		}
	}	
	
	
	.no-cost {
		&__head {
			&__item:not(:empty) {
				width: 200px;
				text-align: center;
			}
		}
		&__body {
			&__text {
				font-size: $s;
			}
		}
	}
	.kukaku-link {
		display: block;
		max-width: 300px;
		margin: 0 auto;
		white-space: nowrap;
		text-decoration: none;
		
		&__img {
			max-width: 100%;
			margin: 0 0 15px;
			@include transition;
		}
		&__txt {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $textColor;
			
			.ico-arrow {
				flex: 0 0 auto;
				margin: 0 8px 0 0;
			}
		}
		&:hover {
			.kukaku-link__img {
				@include opacity;
			}
			.kukaku-link__txt {
				color: $baseColor;
			}
		}
	}
}