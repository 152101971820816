@charset "utf-8";
/* ============================================================ */
/* /company/staff.html */
/* ============================================================ */
#company_staff {
	
	.staff-img {
		text-align: center;
		
		&  > * {
			width: 100%;
			max-width: 720px;
		}
	}
	
}
