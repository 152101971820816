@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	
	&__inner {
		margin: 0 0 40px;
		padding: 0 15px;
	}
	.main {
		margin: 0 0 50px;
		width: 100%;
	}
	.side {
		width: 100%;
	}
}





/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */
.block {
	margin: 0 0 40px;
	
	&--mb-s {
		margin: 0 0 30px;
	}
	&--mb-xs {
		margin: 0 0 20px;
	}
}


/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-bold {
	@include font-bold;
}



/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	margin: 0 0 20px;
	font-size: $xxl;
	line-height: 1.2;
	@include font-bold;
	
	&:not(.ttl01--mv) {
		padding: 0 0 0 15px;
		border-left: 5px solid $baseColor;
	}
	
	&--pink {
		background: #fff1f6;
		border-left: none !important;
		height: 90px;
		margin-top: -30px;
		padding: 0 15px !important;
		display: flex;
		align-items: center;
	}
	
	&--mv {
		display: flex;
		align-items: center;
		height: 100px;
		margin-top: -30px;
		padding: 0 15px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(http://placehold.jp/1920x180.png);
		
		.beginner & {
			background-image: url("/common/img/beginner/bg_ttl.jpg");
		}
		#beginner_choose & {
			background-image: url("/common/img/beginner/choose/bg_ttl.jpg");
		}
		#beginner_point & {
			background-image: url("/common/img/beginner/point/bg_ttl.jpg");
		}
		#beginner_eidai & {
			background-image: url("/common/img/beginner/eidai/bg_ttl.jpg");
		}
		#beginner_kaisou & {
			background-image: url("/common/img/beginner/kaisou/bg_ttl.jpg");
		}
		#beginner_flow & {
			background-image: url("/common/img/beginner/flow/bg_ttl.jpg");
		}
		#beginner_group & {
			background-image: url("/common/img/beginner/group/bg_ttl.jpg");
		}
		#beginner_movie & {
			background-image: url("/common/img/beginner/movie/bg_ttl.jpg");
			background-position: center right;
		}
		.kukaku & {
			background-image: url("/common/img/kukaku/bg_ttl.jpg");
		}
		#kukaku_ippan & {
			background-image: url("/common/img/kukaku/ippan/bg_ttl.jpg");
		}
		#kukaku_roman & {
			background-image: url("/common/img/kukaku/roman/bg_ttl.jpg");
		}
		#kukaku_kuyou_index & {
			background-image: url("/common/img/kukaku/kuyou/bg_ttl.jpg");
		}
		#kukaku_jumoku_index & {
			background-image: url("/common/img/kukaku/jumoku/bg_ttl.jpg");
		}
		#kukaku_viola & {
			background-image: url("/common/img/kukaku/viola/bg_ttl.jpg");
		}
		#kukaku_green-load & {
			background-image: url("/common/img/kukaku/green-load/bg_ttl.jpg");
		}
		#kukaku_canna & {
			background-image: url("/common/img/kukaku/canna/bg_ttl.jpg");
		}
		#kukaku_mimosa & {
			background-image: url("/common/img/kukaku/mimosa/bg_ttl.jpg");
		}
		#kukaku_poplar & {
			background-image: url("/common/img/kukaku/poplar/bg_ttl.jpg");
		}
		#kukaku_sion & {
			background-image: url("/common/img/kukaku/sion/bg_ttl.jpg");
		}
		#kukaku_mint & {
			background-image: url("/common/img/kukaku/mint/bg_ttl.jpg");
		}
		#kukaku_set & {
			background-image: url("/common/img/kukaku/set/bg_ttl.jpg");
		}
		#kukaku_kuyou_one & {
			background-image: url("/common/img/kukaku/kuyou/one/bg_ttl.jpg");
		}
		#kukaku_kuyou_two & {
			background-image: url("/common/img/kukaku/kuyou/two/bg_ttl.jpg");
		}
		#kukaku_kuyou_three & {
			background-image: url("/common/img/kukaku/kuyou/three/bg_ttl.jpg");
		}
		#kukaku_kuyou_four & {
			background-image: url("/common/img/kukaku/kuyou/four/bg_ttl.jpg");
		}
		#kukaku_jumoku_one & {
			background-image: url("/common/img/kukaku/jumoku/one/bg_ttl.jpg");
		}
		#kukaku_jumoku_woman & {
			background-image: url("/common/img/kukaku/jumoku/woman/bg_ttl.jpg");
		}
		#kukaku_jumoku_two & {
			background-image: url("/common/img/kukaku/jumoku/two/bg_ttl.jpg");
		}
		#kukaku_gassou & {
			background-image: url("/common/img/kukaku/gassou/bg_ttl.jpg");
		}
		#kukaku_pet_index & {
			background-image: url("/common/img/kukaku/pet/bg_ttl.jpg");
		}
		#kukaku_pet_senyou & {
			background-image: url("/common/img/kukaku/pet/senyou/bg_ttl.jpg");
		}
		#kukaku_pet_together & {
			background-image: url("/common/img/kukaku/pet/together/bg_ttl.jpg");
		}
		.hoyo & {
			background-image: url("/common/img/hoyo/bg_ttl.jpg");
		}
		#hoyo_kandou & {
			background-image: url("/common/img/hoyo/kandou/bg_ttl.jpg");
		}
		#hoyo_henreihin & {
			background-image: url("/common/img/hoyo/henreihin/bg_ttl.jpg");
		}
		#hoyo_kaisyoku & {
			background-image: url("/common/img/hoyo/kaisyoku/bg_ttl.jpg");
		}
		#hoyo_yasuragi & {
			background-image: url("/common/img/hoyo/yasuragi/bg_ttl.jpg");
		}
		#hoyo_kutsurogi & {
			background-image: url("/common/img/hoyo/kutsurogi/bg_ttl.jpg");
		}
		#hoyo_hohoemi & {
			background-image: url("/common/img/hoyo/hohoemi/bg_ttl.jpg");
		}
		.service & {
			background-image: url("/common/img/service/bg_ttl.jpg");
		}
		.customer & {
			background-image: url("/common/img/customer/bg_ttl.jpg");
		}
		.reservation & {
			background-image: url("/common/img/reservation/bg_ttl.jpg");
		}
	}
	&--mv &__txt {
		width: 100%;
		color: $white;
		text-shadow: 0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5);
	}
}

.ttl02 {
	position: relative;
	margin: 0 0 20px;
	font-size: $xl;
	line-height: 1.2;
	color: $textColor;
	@include font-bold;
	
	&::before {
		display: block;
		color: $baseColor;
		@include font-poppins;
		font-size: $l;
		text-transform: uppercase;
		content: attr(data-text);
		margin: 0 0 5px;
		font-weight: normal;
		font-size: $xs;
	}
	
	&--icon {
		padding: 16px 0 0 35px;
		border-top: 1px solid $borderColor;
		
		_:-ms-fullscreen, :root & {
			padding: 20px 0 0 35px;
		}
		
		&::before {
			top: 16px;
			left: 0;
			position: absolute;
			content: '';
			width: 25px;
			height: 25px;
			margin: 0;
			background: url("/common/img/common/ico_ttl.svg") 0 0 no-repeat;
			font-size: 3.0rem;
		}
		
		&::after {
			position: absolute;
			content: " ";
			display: block;
			border-bottom: 4px solid #ff8db4;
			top: -1px;
			left: 0;
			width: 45%;
		}
	}
	
	&--pink {
		border: 1px solid #F8DCE5;
		border-top: 4px solid $baseColor;
		background: #FFF6F9;
		padding: 5px 15px 10px;
		border-radius: 0 0 3px 3px;
	}
}

.ttl03 {
	position: relative;
	margin: 0 0 15px;
	font-size: $l;
	line-height: 1.4;
	@include font-bold;
	
	&:not(&--line) {
		color: $baseColor;
		text-align: center;
	}
	&--line {
		padding: 8px 10px;
		border-top: 3px solid $baseColor;
		border-bottom: 1px solid $borderColor;
		background: $white;
	}
	&--noline {
		color: $textColor !important;
		text-align: left !important;
		font-size: 1.7rem;
		margin: 0 0 15px !important;
		
		&::after {
			display: none;
		}
	}
}

.ttl04 {
	margin: 0 0 15px;
	padding: 7px 10px 7px 25px;
	background: #FFEEF3;
	/*border-bottom: 1px solid $baseColor;*/
	font-size: $l;
	@include font-bold;
	line-height: 1.4;
	position: relative;
	@include radius(3);
	
	&::before {
		position: absolute;
		top: 50%;
		left: 0;
		content: '';
		height: 3px;
		width: 15px;
		background: $baseColor;
	}	
	
	&--pink {
		color: $baseColor;
	}
}

.ttl05 {
	margin: 0 0 15px;
	padding: 0 0 10px;
	font-size: 1.7rem;
	@include font-bold;
	line-height: 1.4;
	padding: 0 0 15px;
	border-bottom: 1px solid $baseColor;
	color: $baseColor;
}

.ttl06 {
	margin: 0 0 15px;
	padding: 0 0 0 30px;
	font-size: $m;
	@include font-bold;
	line-height: 1.4;
	position: relative;
	
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: '';
		width: 24px;
		height: 24px;
		background: url("/common/img/common/ico_ttl05.svg") 0 0 no-repeat;
	}
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}

	&--brown {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--pink {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: $white;
	@include radius(3);

	&--pink {
		background: $lightColor;
	}
	&--gray {
		background: $gray;
	}
	&--yellow {
		background: #fefaef;
		border: 1px solid #e9e4d4;
	}
	&--border-pink {
		border: 2px solid $baseColor;
	}
	&--border-lightpink {
		border: 2px solid $lightColor;
	}
	&--border-gray {
		border: 2px solid $borderColor;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* panel */
/* --------------------------------------------------- */
.panel {
	border: 1px solid $borderColor;
	
	&__ttl {
		padding: 12px 15px;
		border-bottom: 1px dotted $borderColor;
		text-align: center;
	}
	&__body {
		padding: 15px;
	}
	&--flow {
		position: relative;
		margin: 0 0 40px;
		
		&:after {
			position: absolute;
			bottom: -32px;
			left: calc(50% - 20px);
			width: 0;
			height: 0;
			border-color: $baseColor transparent transparent;
			border-style: solid;
			border-width: 20px 20px 0;
			content: "";
		}
		
		&:last-of-type {
			margin-bottom: 0;
			
			&::after {
				display: none;
			}
		}
	}
	&--flow &__body {
		padding: 20px;			
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	border: 1px solid $borderColor;
	table-layout: fixed;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		background: $white;
		border: 1px solid $borderColor;
		text-align: left;
		vertical-align: middle;
	}
	.bg-pink {
		background: $baseColor;
		color: $white;
	}
	.bg-pink-light {
		background: $lightColor;
	}
	.bg-beige {
		background: #f4f1ea;
	}
	.bg-white {
		background: $white;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
	
	&--center {
		th,
		td {
			text-align: center;
		}
	}	
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
			border: none;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	
}

.table-scroll {
	&__txt {
		font-size: $xxs;
	}
	&__table {
		width: 100%;
		margin: 10px 0;
		overflow-x: auto;
		
		.table {
			width: auto;
			table-layout: auto;
			
			th,
			td {
				width: auto;
			}
		}
	}
}

.table-wrap {
	width: 100%;
	padding: 0 0 5px;
	overflow-x: auto;
	
	.table {
		width: 150%;
	}
	&::-webkit-scrollbar {
		height: 5px;
	}
	&::-webkit-scrollbar-track{
		background: #f2f2f2;
	}
	&::-webkit-scrollbar-thumb {
		background: #c2c2c2;
	}	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	min-height: 50px;
	height: auto;
	padding: 0 20px;
	font-size: $m;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	@include radius(3);
	@include font-bold;
	
	_:-ms-fullscreen, :root & {
		height: 50px;
	}
	
	.icon-filter_none {
		color: $white;
	}
	
	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 10px; }
	.icon-fix-right { right: 10px; }
	
	&--pink {
		background: $baseColor;
		border: 1px solid $baseColor;
		color: $white;
	}
	
	&--brown {
		background: $brown;
		border: 1px solid $brown;
		color: $white;
	}
	
	&--border {
		border: 1px solid $borderColorBeige;
		background: $white;
		color: $textColor;
		
		.icon-fix-right {
			color: $borderColorBeige;
		}
	}
	
	&--border-gray {
		border: 1px solid $borderColor;
		background: $white;
		color: $textColor;
		
		.icon-fix-right {
			color: $baseColor;
		}
	}

	&--lg {
		width: 100%;
		height: 50px;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		min-height: 35px;
		padding: 0 20px;
		font-size: $xxs;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}

.btn-block {
	display: flex;
	align-items: center;
	justify-content: center;
	
	& > * {
		margin: 0 10px;
		
		@media screen and (max-width: 320.499px) {
			margin: 0 5px;
		}
	}
}


/* --------------------------------------------------- */
/* ico-arrow */
/* --------------------------------------------------- */
.ico-arrow {
	border: 1px solid $borderColor;
	color: $baseColor;
	background: $white;
	@include circle(30);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: $l;
	line-height: 1.2;
	
	&--sm {
		@include circle(22);
		font-size: $m;
	}
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: $white;
			border: 1px solid $borderColor;
			color: $textColor;
			text-decoration: none;
			@include radius(3);
			
			&.current {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: #fff;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* ank-link */
/* --------------------------------------------------- */
.ank-link {
	border: 1px solid #f3d2dd;
	border-bottom: none;
	
	&__item {
		border-bottom: 1px solid #f3d2dd;
		
		&__link {
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			text-align: center;
			font-size: $s;
			color: $textColor;
			height: 45px;
			position: relative;
			background: $lightColor;
			line-height: 1.3;
			
			.icon-fix-left,
			.icon-fix-right {
				@include centering-elements(false, true);
				color: $baseColor;
			}
			.icon-fix-left { left: 10px; }
			.icon-fix-right { right: 10px; }
		}
	}
}



/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 15px;
	background: #f4f1ea;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	font-size: $s;
	
	&__icon {
		&::before {
			content: "\e90d";
			font-family: 'icomoon';
			display: block;
			line-height: 1.0;
			color: $baseColor;
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translate(0, -50%);
			font-size: $l;
		}
	}
	&.active {
		.accordion__icon {
			&::before {
				content: "\e90e";
			}
		}
	}
}
.accordion-hide {
	display: none;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.txt-error {
	margin: 10px 0 0;
	color: #dd0000;
	font-size: $m;
	line-height: 1.4;
	@include font-bold;
}

input[type="text"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid $borderColor;
	@include font-normal;
	@include radius(0);
	
	&.w-s {
		width: 150px;
	}
	&.w-m {
		width: 100%;
	}
	&.w-l {
		width: 100%;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid $borderColor;
	@include font-normal;
	@include radius(0);
}

input::placeholder,
textarea::placeholder {
	color: rgba($textColor, .4);
	opacity: 1;
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid $borderColor;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	display: inline-block;
	text-align: center;
	padding: 2px 5px;
	min-width: 95px;
	box-sizing: border-box;
	color: $white;
	font-size: $xxs;
	@include radius(3);
	font-weight: normal;
	
	&--info,
	&--orange {
		background: $orange;
	}
	
	&--cat41,
	&--mint {
		background: $mint;
	}
	
	&--pink ,
	&--cons {
		background: $baseColor;
	}

	&--event {
		background: #619816;
	}

	&--memorial {
		background: #9c66c2;
	}

	&--blog {
		background: #4dabfa;
	}

	&--cat32 {
		background: #854718;
	}

	&--cat42 {
		background: #067e55;
	}

	&--link {
		text-decoration: none;
	}

}


/* --------------------------------------------------- */
/* block-contact */
/* --------------------------------------------------- */
.block-contact {
	position: relative;
	background: #FFF8FA;
	margin: -6px 0 0;
	padding: 35px 0;
	
	&::after {
		display: inline-block;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 180px;
    background: url("/common/img/common/bg_contents_bottom.jpg") center bottom no-repeat;
    background-size: 100%;
    content: "";
	}
	
	&__ttl {
		text-align: center;
		position: relative;
		z-index: 2;
	}
	
	&__inner {
		margin: 0 15px;
		position: relative;
		z-index: 2;
				
		&__item {
			margin: 0 0 15px;
			
			&__link {
				background: url("/common/img/common/bg_bnr.png") 0 0 repeat;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include radius(3);
				color: $white;
				padding: 10px 15px;
				box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
				text-decoration: none;
				
				&__img {
					width: 60px;
				}
				
				&__text {
					width: calc(100% - 70px);
					
					&__ttl {
						font-size: $l;
						@include font-bold;
						line-height: 1.4;
					}
					
					&__sub {
						font-size: $s;
						color: #FFF5CF;
						line-height: 1.4;
					}
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* .beginner-bnr */
/* --------------------------------------------------- */
.beginner-bnr {
	margin: 0 15px 40px;

	&__inner {
		&__item {
			margin: 0 0 10px;
			
			&__link {
				background: url("/common/img/common/bg_bnr.png") 0 0 repeat;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include radius(3);
				color: $white;
				padding: 10px 15px;
				box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
				text-decoration: none;
				
				&__img {
					width: 60px;
				}
				
				&__text {
					width: calc(100% - 70px);
					
					&__ttl {
						font-size: $l;
						@include font-bold;
						line-height: 1.4;
					}
					
					&__sub {
						font-size: $s;
						color: #FFF5CF;
						line-height: 1.4;
					}
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* link-list */
/* --------------------------------------------------- */
.link-list {
	margin: 20px 0 0;
	
	&__item {
		margin: 0 0 20px;
		border: 1px solid #D9D9D9;
		@include radius(3);
		
		&__link {
			text-decoration: none;
			color: $textColor;
			
			&__ttl {
				padding: 10px 15px;
				border-bottom: 1px dotted #D9D9D9;
				text-align: center;
				@include font-bold;
				font-size: $l;
				color: $baseColor;
				background: #FFF6F9;
				line-height: 1.3;
				
				&__inner {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					
					&__text {
						max-width: calc(100% - 32px);
						text-align: left;
					}
				}
				
				.ico-arrow {
					margin: 2px 10px 0 0;
					width: 22px;
					height: 22px;
					background: $baseColor;
					color: $white;
					border: 1px solid $baseColor;
					font-size: $s;
				}
			}
			
			&__img {
				padding: 15px 15px 0;
				margin: 0 0 10px;
			}

			&__text {
				padding: 0 15px 20px;
				font-size: $s;
				line-height: 1.5;
			}
		}
	}
}



/* --------------------------------------------------- */
/* common-service */
/* --------------------------------------------------- */
.common-service {
	&__item {
		margin: 0 0 20px;
		padding: 0 0 20px;
		border-bottom: 1px dotted $borderColor;
		
		&__img {
			margin: 0 0 15px;
		}
		
		&__text {
		}
	}
}


/* ============================================================ */
/* .common-btn */
/* ============================================================ */
.common-btn {
	margin: 20px 0 0;

	&__inner {			
		&__item {
			margin: 0 auto 10px;
			width: 85%;
		}
	}

	.btn {
		width: 100%;
		min-width: auto !important;
	}
}



/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	margin: 0 15px 35px;
	
	&__ttl {
		padding: 0 0 0 15px;
		margin: 0 0 20px;
		border-left: 3px solid $baseColor;
		font-size: $xl;
		color: $textColor;
		@include font-bold;
	}
	
	&__ttl02 {
		.ico-arrow {
			@include circle(25);
			font-size: 1.5rem;
			line-height: 1.0;
			margin: 0 10px 0 0;
		}
		
		&__link {
			text-decoration: none;
		}
	}
	
	&__inner {		
		margin: 0 0 20px;
		
		&:last-of-type {
			margin: 0;
		}
		
		&__item {
			margin: 0 0 10px;
			
			&__link {
				border: 1px solid $borderColor;
				background: $white;
				@include radius(3);
				padding: 10px 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-decoration: none;
				@include transition;
				color: $textColor;
				
				&::after {
					content: "\e901";
					color: $baseColor;
					font-family: 'icomoon';
				}
				
				&.current ,
				&:hover {
					background: $lightColor;
					@include opacity;
					opacity: 1;
					color: $baseColor;
					@include font-bold;
				}
				
				&__img {
					width: 60px;
					margin: 0 10px 0 0;
					@include radius(3);
					
					img {
						@include radius(3);
					}
				}
				
				&__text {
					width: calc(100% - 70px);
					line-height: 1.4;
					font-size: $s;
					display: flex;
					align-items: center;
					
					&__sub {
						/*br {
							display: none;
						}*/
					}
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 40px;
	
	&__item {
		margin-bottom: 30px;
		
		&__link {
			display: block;
			text-decoration: none;
			color: $textColor;

			&__img {
				width: 100%;
				height: 120px;
				margin: 0 0 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				border: 1px solid $borderColor;

				@media screen and (min-width: 411px) and (max-width: 845.5px) {
					height: 170px;
				}
				&__inner {
					width: auto;
				}
			}
			&__head {
				margin: 0 0 5px;
				
				&__date {
					font-size: $xxs;
					margin: 3px 0 0;
				}
			}
			&__ttl {
				text-decoration: underline;
				color: $baseColor;
				margin: 0 0 5px;
				font-size: $xs;
				line-height: 1.5;
				position: relative;
				max-height: calc(13 * 1.5 * 2 * 1px);
				line-height: 1.6;
				word-break: break-all;
				overflow: hidden;

				&::before,
				&::after {
					position: absolute;
					background: $white;
				}
				&::before {
					content: '...';
					top: calc(13 * 1.5 * (2 - 1) * 1px);
					right: 0;
					width: 1.9em;
				}
				&::after {
					content: '';
					width: 100%;
					height: 100%;
				}			
			}
			&__date {
				font-size: $s;
				margin: 0 0 5px;
			}
		}
	}
}


/* --------------------------------------------------- */
/* customer-list */
/* --------------------------------------------------- */
.customer-list {
	margin: 0 0 40px;
	
	&__item {
		margin-bottom: 30px;
		
		&__link {
			display: block;
			text-decoration: none;
			color: $textColor;

			&__img {
				width: 100%;
				height: 120px;
				margin: 0 0 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				border: 1px solid $borderColor;

				@media screen and (min-width: 411px) and (max-width: 845.5px) {
					height: 240px;
				}
				&__inner {
					width: auto;
				}
			}
			&__head {
				margin: 0 0 10px;
			}
			&__ttl {
				text-decoration: underline;
				color: $baseColor;
				font-size: $xxs;
				word-break: break-all;
			}
			&__date {
				font-size: $xs;
				margin: 0 0 3px;
			}
		}
	}
}

/* --------------------------------------------------- */
/* blog-bnr */
/* --------------------------------------------------- */
.blog-bnr {
	margin: 0 0 20px;
	
	&__tree {
		display: block;
	}
}


/* --------------------------------------------------- */
/* link-arrow */
/* --------------------------------------------------- */
.link-arrow {
	display: flex;
	
	&__ico {
		flex: 0 0 auto;
		margin: 5px 8px 0 5px;
		color: $baseColor;
	}
	&__link {
		text-decoration: none;
	}
}


/* --------------------------------------------------- */
/* contact-box */
/* --------------------------------------------------- */
.contact-box {
	width: 100%;
	padding: 15px;
	background: $lightColor;
	text-align: center;
	line-height: 1.4;
	@include radius(3);
	
	&__ttl {
		margin: 0 0 5px;
		@include font-bold;
	}
	&__phone {
		margin: 0 0 10px;
		
		&__ico {
			margin: 0 5px 0 0;
			color: $freecall;
			font-size: $xxxl;
		}
		&__link {
			font-size: $xxxl;
			@include font-bold;
		}
	}
}



/* --------------------------------------------------- */
/* pic-slider */
/* --------------------------------------------------- */
.pic-slider {
	width: 100%;
	height: 200px;
	overflow: hidden;

	@media screen and (min-width: 475px) and (max-width: 845.499px) {
		height: 350px;
	}

	&__inner {
		height: 200px;
		position: relative;

		@media screen and (min-width: 475px) and (max-width: 845.499px) {
			height: 350px;
		}

		&__item {
			height: 200px;

			@media screen and (min-width: 475px) and (max-width: 845.499px) {
				height: 350px;
			}

			.object-fit-img {
				@media screen and (min-width: 475px) and (max-width: 845.499px) {
					height: 350px;
				}

				height: 200px;
				object-fit: cover;
				object-position: center center;
				font-family: 'object-fit: cover; object-position: center center;'
			}
		}

		&__slider {
			height: 200px;
			overflow: hidden;
			position: relative;

			@media screen and (min-width: 475px) and (max-width: 845.499px) {
				height: 350px;
			}
		}
	}
	
	.slick-prev,
	.slick-next {
		position: absolute;
		top: 50%;
		z-index: 50;
		transform: translate(0, -50%);
	}
	
	.slick-prev {
		left: 10px;
	}
	
	.slick-next {
		right: 10px;
	}
	
	.slick-list ,
	.slick-track {
		height: 200px;
		
		@media screen and (min-width: 475px) and (max-width: 845.499px) {
			height: 350px;
		}
	}
	
	.slick-dots {
		margin: -40px 0 0;
		position: relative;
		z-index: 2;
	}
}

/* --------------------------------------------------- */
/* pic-slider02 */
/* --------------------------------------------------- */
.pic-slider02 {
	&-main {
		&__item {
			position: relative;
			overflow: hidden;

			.__caption {
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
				padding: 10px 15px;
				background: rgba(#000, .35);
				color: $white;
				font-size: $m;
			}
		}
	}
	&-thumb {
		position: relative;
		margin: 15px -3px 0;

		&__item {
		}
		
		.slick-list {
		}
		.slick-slide {
			padding: 0 3px;
			cursor: pointer;
		}
		.slick-prev,
		.slick-next {
			z-index: 1;
			width: 24px;
			height: 24px;
			border-radius: 0;
			border: none;
			background: $baseColor;
			@include centering-elements(false, true);

			&::before {
				color: $white;
				opacity: 1;
				font-size: $l;
			}
		}
		.slick-prev {
			left: 3px;

		}
		.slick-next {
			right: 3px;
		}
	}	
	
}





/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	margin: 0 0 30px;
	line-height: 1.4;

	&__ttl {
		&__link {
			display: flex;
			align-items: center;
			padding: 15px;
			background: url("/common/img/common/bg_snav.png") 0 0 repeat;
			color: $textColor;
			font-size: $m;
			text-decoration: none;
			@include radius(3);
			@include font-bold;
			@include transition;

			&::before {
				flex: 0 0 auto;
				content: '';
				width: 30px;
				height: 25px;
				margin: 0 10px 0 0;
				background: url("/common/img/common/ico_snav.svg") 0 0 no-repeat;
			}
		}
	}
	&__item {
		display: flex;
		align-items: center;
		padding: 15px 10px;
		border-bottom: 1px solid $borderColor;
		color: $textColor;
		text-decoration: none;

		&__txt {
			flex: 1 1 auto;
		}
		.icon-angle-right {
			flex: 0 0 auto;
			margin: 0 10px 0 0;
			color: $baseColor;
		}
		&.current {
			color: $baseColor;
		}
	}
	&__inner {
		&__sub {
			&__item {
				display: flex;
				align-items: center;
				padding: 15px 20px;
				color: $textColor;
				text-decoration: none;
				line-height: 1.5;
				border-bottom: 1px dotted $borderColor;
				
				&:last-of-type {
					border-bottom: 1px solid $borderColor;		
				}
				
				.icon-angle-right {
					flex: 0 0 auto;
					margin: 0 10px 0 0;
					color: $baseColor;
				}
				
				&.current {
					color: $baseColor;
				}
			}
		}
		
		&__month {
			&__text {
				padding: 15px 10px;
				border-bottom: 1px solid $borderColor;
				display: block;
				position: relative;
				cursor: pointer;
				
				&__ico {
					&::before {
						content: "\e90d";
						font-family: 'icomoon';
						display: block;
						line-height: 1.0;
						color: $baseColor;
						position: absolute;
						top: 50%;
						right: 15px;
						transform: translate(0, -50%);
						font-size: $xxl;
					}
				}
				&.active {
					.snav__inner__month__text__ico {
						&::before {
							content: "\e90e";
						}
					}
				}
			}
			
			&__list {
				display: none;
				
				&__item {
					&:last-of-type {
						.snav {
							&__item {
								border-bottom: 1px solid $borderColor;
							}
						}
					}
					
					.snav {
						&__item {
							padding: 15px 20px;
						}
					}
				}
			}
		}
	}
}

/* --------------------------------------------------- */
/* side_bnr */
/* --------------------------------------------------- */
.side_bnr {
	&__item {
		margin: 0 0 10px;
		
		&__link {
			padding: 15px;
			border: 1px solid $borderColor;
			color: $textColor;
			font-size: $m;
			text-decoration: none;
			line-height: 1.4;
			@include radius(3);
			display: flex;
			@include font-bold;
			align-items: center;
			
			&__ico {
				color: $baseColor;
				font-size: $xxl;
				margin: 0 10px 0 0;
			}

		}
	}
}


