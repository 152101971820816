@charset "utf-8";
/* ============================================================ */
/* /catalogue/ */
/* ============================================================ */
.catalogue {
	
	.catalogue-info {
		&__img {
			width: 222px;
			margin: 0 auto 15px;
			border: 1px solid $borderColor;
		}
		&__txt {
			line-height: 1.4;
		}
	}	
	
	.modal {
		height: 90vh;
		padding: 30px 20px 25px !important;
		
		&__body {
			height: 100%;
			
			.__iframe {
				width: 100%;
				height: 100%;
			}
		}
		&__close {
			width: 30px;
			height: 30px;
			font-size: $m;
			color: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $baseColor;
			position: absolute;
			top: 0;
			right: 0;
			@include transition;
			cursor: pointer;
		}
	}
}

