@charset "utf-8";
/* ============================================================ */
/* ご法要ページ共通 */
/* ============================================================ */
.hoyo {
	.plan-price {
		&__text {
			color: #F56797;
			font-size: $l;
			margin: 0;
			@include font-bold;
		}
	}
	
	.link-list {
		.list {
			margin: 0 0 10px;
		}
	}
	
	.flow-img {
		max-width: 140px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.plan-num {
		display: flex;
		
		&__ico {
			background: $baseColor;
			color: $white;
			@include circle(30);
			min-width: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 12px 0 0;
			line-height: 1.0;
			@include font-bold;
			
			_:-ms-fullscreen, :root & {
				line-height: 0.4;
			}
		}
	}
}


/* ============================================================ */
/* #hoyo_henreihin */
/* ============================================================ */
#hoyo_henreihin {
	.henrei-img {
		&--border {
			border: 1px solid $borderColor;
		}
	}
	
	.table {
	}
}

