@charset "utf-8";
/* ============================================================ */
/* /catalogue/ */
/* ============================================================ */
.catalogue {
	
	.catalogue-info {
		&__img {
			width: 222px;
			margin: 0 auto 15px;
			border: 1px solid $borderColor;
		}
		&__txt {
			font-size: $m;
			line-height: 1.4;
		}
	}	
	
	@media screen and (max-width: 1100px) {
		.column3 {
			& > * {
				width: calc((100% - 61px) / 2);
			}
		}
	}
	
	.modal {
		width: 100%;
		height: 650px;
		max-width: 1000px;
		
		&__body {
			height: 100%;
			
			.__iframe {
				width: 100%;
				height: 100%;
			}
		}
		&__close {
			@include circle(40);
			font-size: $xl;
			color: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $baseColor;
			position: absolute;
			top: 15px;
			right: 15px;
			@include transition;
			cursor: pointer;

			&:hover {
				@include opacity;
			}
		}
	}
}
