@charset "utf-8";

$baseColor: #F56797;
$textColor: #3D160A;
$lightColor: #FFF6F9;
$borderColor: #D9D9D9;
$borderColorBeige: #C9B997;
$borderColorPink: #F3D9E2;

$white: #fff;
$orange: #FFA136;
$mint: #52C9B5;
$gray: #F3F3F3;
$brown: #502D22;
$freecall: rgba(17, 25, 135, 1);

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin font-poppins {
	font-family: "Poppins", sans-serif;
}

@mixin font-normal {
	font-family: "Noto Sans JP", sans-serif;
	font-weight: 400;
}

@mixin font-bold {
	font-family: "Noto Sans JP", sans-serif;
	font-weight: 500;
}

/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;

