@charset "utf-8";
/* ============================================================ */
/* #sitemap_index */
/* ============================================================ */
.sitemap-level1 {
	&__item {
		&__link {
			display: flex;
			align-items: center;
			padding: 12px 15px;
			background: $lightColor;
			color: $baseColor;
			font-size: $l;
			font-feature-settings: 'palt';
			letter-spacing: 0.02em;
			text-decoration: none;
			line-height: 1.4;			
			@include font-bold;
			
			&__ico {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 10px 0 0;
				background: $baseColor;
				color: $white;
				font-size: $s;
				@include circle(20);
			}
		}
	}
}

.sitemap-level2 {
	&__wrap {
		margin: 20px 10px 0;
	}
	&__item {
		margin-bottom: 5px;
		
		&__link {
			display: flex;
			color: $textColor;
			font-size: $s;
			font-feature-settings: 'palt';
			text-decoration: none;
			@include transition;
			
			&__ico {
				flex: 0 0 auto;
				margin: 4px 6px 0 0;
				color: $baseColor;
			}
			&:hover {
				color: $baseColor;
			}
		}
	}
}