@charset "utf-8";
/* ============================================================ */
/* 区画共通部分 */
/* ============================================================ */
.kukaku {
	.ank-link {
		border: none;
		border-left: 1px solid #f3d2dd;
		
		&__item {
			&:nth-child(1) ,
			&:nth-child(2) ,
			&:nth-child(3) {
				border-top: 1px solid #f3d2dd;
			}
			
			&:nth-child(3n) {
				border-right: 1px solid #f3d2dd !important;
			}
		}
	}
	
	.kukaku-movie {
		max-width: 900px;
		margin: 0 auto;
	}
	
	.kukaku-voice {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;

		@media screen and (max-width: 1280px) {
			margin: 0 -10px;
		}

		&__item {
			border: 1px solid #D9D9D9;
			width: calc((100% - 61px) / 2);
			margin: 0 15px 30px;

			@media screen and (max-width: 1280px) {
			}

			@media screen and (max-width: 1100px) {
			}

			&__link {
				text-decoration: none;
				color: $textColor;
				@include transition;
				display: block;
				
				&:hover {
					@include opacity;
				}

				&__ttl {
				padding: 15px 20px;
				border-bottom: 1px dotted #D9D9D9;
				text-align: center;
				@include font-bold;
				font-size: $xxxl;
				color: $baseColor;
				background: #FFF6F9;
				line-height: 1.3;
				
				@media screen and (max-width: 1280px) {
					font-size: 1.9rem;
				}
				
				&__inner {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					
					&__text {
						max-width: calc(100% - 32px);
						text-align: left;
					}
				}
				
				.ico-arrow {
					margin: -3px 10px 0 0;
					width: 22px;
					height: 22px;
					background: $baseColor;
					color: $white;
					border: 1px solid $baseColor;
					font-size: $s;
				}
			}
			
			&__img {
				padding: 20px 20px 0;
				margin: 0 0 15px;
			}

			&__text {
				padding: 0 20px 30px;
				font-size: $l;
			}
			}
		}
	}
	
	.set-plan {
		.box {
			color: $baseColor;
			font-size: $xl;
			margin: 0 0 20px;
		}
		&__note {
			margin: 5px 0 0;
			color: $textColor;
			font-size: $m;
			font-feature-settings: 'palt';
			letter-spacing: 0.05em;
		}
		&__text {
			font-size: 1.5rem;
		}
	}
	
	.snav {
		&__item {
			.icon-angle-right {
				margin: 0;
				font-size: $xxl;
			}
		}
	}
}

.ippan {
	.eidai01 ,
	.eidai02 ,
	.pet {
		display: none;
	}
}
.kukaku_kuyou {
	.komichi ,
	.eidai02 ,
	.pet {
		display: none;
	}
}
.kukaku_jumoku {
	.komichi ,
	.eidai01 ,
	.pet {
		display: none;
	}
}
.kukaku_pet {
	.eidai01 ,
	.eidai02 ,
	.komichi {
		display: none;
	}
}

/* ============================================================ */
/* kukaku_index */
/* ============================================================ */
#kukaku_index {
	.komichi ,
	.eidai01 ,
	.eidai02 ,
	.pet {
		display: none;
	}
}

/* ============================================================ */
/* #kukaku_ippan, #kukaku_kuyou_index,
   #kukaku_jumoku_index, #kukaku_pet_index */
/* ============================================================ */
#kukaku_ippan,
#kukaku_kuyou_index ,
#kukaku_jumoku_index,
#kukaku_pet_index {
	.box {
		margin: 40px 0 60px;
		&--note {
			font-size: $m;
		}
	}
}


/* ============================================================ */
/* #kukaku_pet_together, #kukaku_pet_senyou */
/* ============================================================ */
#kukaku_pet_together,
#kukaku_pet_senyou {
	.ttl05 {
		font-size: $xl;
	}
}

/* ============================================================ */
/* kukaku_gassou */
/* ============================================================ */
#kukaku_gassou,
#beginner_flow,
#beginner_eidai,
#beginner_group {
	.eidai01 ,
	.eidai02 ,
	.pet ,
	.komichi {
		display: none;
	}
}