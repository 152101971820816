@charset "utf-8";
/* ============================================================ */
/* .beginner-btn */
/* ============================================================ */
.beginner-btn {
	display: flex;
	justify-content: flex-end;
	margin: 25px -4px 0;

	&__item {
		width: calc((100% - 17px) / 2);
		margin-left: 4px;
		margin-right: 4px;

		.btn {
			width: 100%;
			min-width: auto !important;
			padding: 0 25px;
			font-size: 1.7rem;

			@media screen and (max-width: 1245px) {
				padding: 0 20px;
			}
		}
	}
}

/* ============================================================ */
/* #beginner_index */
/* ============================================================ */
#beginner_index {
	.concept-ttl {
		color: $baseColor;
		text-align: center;
		margin: 0 0 30px;
		padding: 100px 0 90px;
		@include font-bold;
		font-size: 3.1rem;
		line-height: 1.4;
		position: relative;
		
		@media screen and (max-width: 1325px) {
			padding: 80px 0;
		}
		
		@media screen and (max-width: 1160px) {
			padding: 60px 0 80px !important;
		}
		
		&::before {
			background: url("/common/img/beginner/bg_lead_top.png") top center no-repeat;
			background-size: contain;
			width: 21%;
			height: 21%;
			content: '';
			display: inline-block;
			@include centering-elements(true, false);
			top: 0;
			z-index: 1;
		}
		
		&::after {
			background: url("/common/img/beginner/bg_lead_bottom.png") top center no-repeat;
			background-size: contain;
			width: 21%;
			height: 21%;
			content: '';
			display: inline-block;
			@include centering-elements(true, false);
			bottom: 0;
			z-index: 1;
		}
		
		&__text {
			color: $textColor;
			font-weight: normal;
			font-size: $xl;
			line-height: 1.6 !important;
			display: inline-block;
			margin: 10px 0 0;
			
			@media screen and (max-width: 1045px) {
				font-size: $l;
			}
		}
	}
	
	.concept-img {
		max-width: 900px;
		margin: 0 auto 40px;
	}
	
	.concept-hanaoka {
		&__name {
			max-width: 130px;
			margin: 15px auto 0;
		}
	}
}


/* ============================================================ */
/* #beginner_choose */
/* ============================================================ */
#beginner_choose {
	.block {
		&:first-of-type {
			margin-top: 60px;
			
			@media screen and (max-width: 1160px) {
				margin-top: 30px;
			}
		}
	}
	
	.choose-img {
		margin: 0 0 25px;
		
		&__innner {
			margin: 0 0 15px;
			
			&__text {
				margin: 10px 0 0;
				text-align: center;
				font-size: $s;
			}
		}
	}
	
	.hikaku-text {
		font-size: $m;
	}
	
	.box {
		margin: 20px 0 0;
	}
	
	.ttl05 {
		font-size: $xl;
	}
	
	.btn {
		br {
			@media screen and (max-width: 1040px) {
				display: none;
			}
		}
		
		.choose-br {
			display: block !important;
		}
	}
	
	.table {
		th {
			text-align: center;
		}
	}
	
	.column3 {
		margin: 0;
		border-left: 1px dotted $borderColor;
		
		&__inner {
			border-right: 1px dotted $borderColor;
			padding: 0 20px;
			margin: 0;
			width: calc((100% - 1px) / 3);
		}
	}
	
	
	.choose-price {
		display: flex;
		border-left: 1px dotted $borderColor;
		
		@media screen and (max-width: 1160px) {
			display: block;
			border-left: none;
		}
		
		&__item {
			width: calc(100% / 2);
			padding: 0 30px;
			border-right: 1px dotted $borderColor;
			
			@media screen and (max-width: 1160px) {
				width: 100%;
				padding: 0 0 20px;
				margin: 0 0 40px;
				border-right: none;
				border-bottom: 1px dotted $borderColor;
				
				&:last-of-type {
					padding: 0;
					margin: 0;
					border-bottom: none;
				}
			}
			
			&__list {
				font-size: $m;
			}
			
			.box {
				margin: 0 0 30px;
				
				@media screen and (max-width: 1000px) {
					padding: 20px;
				}
			}
			
			.list {
				li {
					margin: 0 0 8px;
				}				
			}
		}
	}
}


/* ============================================================ */
/* #beginner_kaisou */
/* ============================================================ */
#beginner_kaisou {
	.panel {
		.link-arrow {
			margin: 0 0 5px;

			&:last-of-type {
				margin: 0;
			}
		}
		&.select-place {
			.flex-column {
				margin-top: 30px;
				padding-top: 30px;
				border-top: 1px dotted $borderColor;
			}
		}
		&__body {
			&__kouji {
				display: flex;
				justify-content: space-between;
				margin: 25px -15px 20px;
				
				&__item {
					margin: 0 15px;
					width: calc((100% - 121px) / 4);
					position: relative;
					
					&:last-of-type {
						&:after {
							display: none;
						}
					}
					
					&:after {
						position: absolute;
						right: -23px;
						top: 50%;
						width: 0;
						height: 0;
						border-color: transparent transparent transparent $baseColor;
						border-style: solid;
						border-width: 15px 0 15px 15px;
						content: "";
					}
					
					.ttl06 {
						padding: 0;
						color: $baseColor; 
						
						@media screen and (max-width: 1000px) {
							font-size: $m;
						}
						
						&::before {
							display: none;
						}
					}
				}
			}
		}
	}
}

/* ============================================================ */
/* #beginner_point */
/* ============================================================ */
#beginner_point {
	.no-cost {
		&__head {
			&__item:not(:empty) {
				width: 26.6%;
				text-align: center;
			}
			&__item:empty {
				width: 20%;
			}
		}
		&__body {
			&__text {
			}
		}
	}
	.kukaku-link {
		display: block;
		max-width: 300px;
		margin: 0 auto;
		text-decoration: none;
		line-height: 1.4;
		
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			text-align: center;
		}
		&__img {
			width: 100%;
			margin: 0 0 15px;
			@include transition;
		}
		&__txt {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $textColor;
			
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				display: inline-table;
				
				& > * {
					display: table-cell;
					vertical-align: middle;
				}
			}
			.ico-arrow {
				flex: 0 0 auto;
				margin: 0 8px 0 0;
			}
		}
		&:hover {
			.kukaku-link__img {
				@include opacity;
			}
			.kukaku-link__txt {
				color: $baseColor;
			}
		}
	}
	
}
