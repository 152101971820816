@charset "utf-8";
/* ============================================================ */
/* slider & iframe */
/* ============================================================ */
.kukaku {
	.kukaku-movie {
	}
	
	.kukaku-voice {
		margin: 0 0 20px;
		
		&__item {
			margin: 0 0 20px;
			border: 1px solid #D9D9D9;
			@include radius(3);

			&__link {
				text-decoration: none;
				color: $textColor;

				&__ttl {
					padding: 10px 15px;
					border-bottom: 1px dotted #D9D9D9;
					text-align: center;
					@include font-bold;
					font-size: $l;
					color: $baseColor;
					background: #FFF6F9;
					line-height: 1.3;

					&__inner {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100%;

						&__text {
							max-width: calc(100% - 32px);
							text-align: left;
						}
					}

					.ico-arrow {
						margin: 2px 10px 0 0;
						width: 22px;
						height: 22px;
						background: $baseColor;
						color: $white;
						border: 1px solid $baseColor;
						font-size: $s;
					}
				}

				&__img {
					padding: 15px 15px 0;
					margin: 0 0 10px;
				}

				&__text {
					padding: 0 15px 20px;
					font-size: $s;
					line-height: 1.5;
				}
			}
		}
	}
	
	.set-plan {
		.box {
			color: $baseColor;
			font-size: $l;
			margin: 0 0 15px;
		}
		&__note {
			margin: 5px 0 0;
			color: $textColor;
			font-size: $s;
			font-feature-settings: 'palt';
			letter-spacing: 0.05em;
		}
		&__text {
			font-size: $s;
		}
	}
}

.snav {
	&__item {
		.icon-angle-right {
			margin: 0 10px 0 0;
			font-size: 2.3rem;
		}
	}
}

.ippan {
	.eidai01 ,
	.eidai02 ,
	.pet {
		display: none;
	}
}
.kukaku_kuyou {
	.komichi ,
	.eidai02 ,
	.pet {
		display: none;
	}
}
.kukaku_jumoku {
	.komichi ,
	.eidai01 ,
	.pet {
		display: none;
	}
}
.kukaku_pet {
	.eidai01 ,
	.eidai02 ,
	.komichi {
		display: none;
	}
}



/* ============================================================ */
/* kukaku_index */
/* ============================================================ */
#kukaku_index {
	.komichi ,
	.eidai01 ,
	.eidai02 ,
	.pet {
		display: none;
	}
}

/* ============================================================ */
/* #kukaku_ippan, #kukaku_kuyou_index, 
   #kukaku_jumoku_index, #kukaku_pet_index */
/* ============================================================ */
#kukaku_ippan,
#kukaku_kuyou_index ,
#kukaku_jumoku_index,
#kukaku_pet_index {
	.box {
		margin: 20px 0 30px;
		&--note {
			font-size: $s;
		}
	}
}

/* ============================================================ */
/* kukaku_gassou */
/* ============================================================ */
#kukaku_gassou,
#beginner_flow,
#beginner_eidai,
#beginner_group {
	.eidai01 ,
	.eidai02 ,
	.pet ,
	.komichi {
		display: none;
	}
}
