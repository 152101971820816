@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sawarabi+Gothic&display=swap');
@import "_reset";
@import "_icon";
@import "_mixin";

@media print, screen and (min-width: 845.5px) {
	@import "pc/_access";
	@import "pc/_base";
	@import "pc/_beginner";
	@import "pc/_catalogue";
	@import "pc/_company";
	@import "pc/_consultation";
	@import "pc/_contents";
	@import "pc/_customer";
	@import "pc/_faq";
	@import "pc/_footer";
	@import "pc/_header";
	@import "pc/_hoyo";
	@import "pc/_index";
	@import "pc/_kukaku";
	@import "pc/_news";
	@import "pc/_reservation";
	@import "pc/_service";
	@import "pc/_sitemap";
	@import "pc/_support";
}

@media screen and (max-width: 845.499px) {
	@import "sp/_access";
	@import "sp/_base";
	@import "sp/_beginner";
	@import "sp/_catalogue";
	@import "sp/_company";
	@import "sp/_consultation";
	@import "sp/_contents";
	@import "sp/_customer";
	@import "sp/_faq";
	@import "sp/_footer";
	@import "sp/_header";
	@import "sp/_hoyo";
	@import "sp/_index";
	@import "sp/_kukaku";
	@import "sp/_news";
	@import "sp/_reservation";
	@import "sp/_service";
	@import "sp/_sitemap";
	@import "sp/_support";
}
@import "_utility";



