@charset "utf-8";
/* ============================================================ */
/* #service_index */
/* ============================================================ */
#service_index {
	.service-img {
		width: 264px;
		height: 200px;
	}
	
	.service-text {
		width: calc(100% - 294px);
	}
	
	.service-visual {
		margin: 0 0 40px;
		
		img {
			/*max-width: 900px;*/
		}
	}
	
	.common-service {
		&__item {
			align-items: flex-start;
			margin: 0;
			padding: 0;
			border-bottom: none;
			
			&__img {
				width: 318px;
				height: 215px;
			}
			
			&__text {
				width: calc(100% - 348px);
			}
		}
	}
	
	.ank-link {
		&__item {
			&__link {
				min-height: 70px;
			}
		}
	}
	
	.link-arrow {
		justify-content: flex-end;
	}
	
	.pic-slider {
		&__inner {
			&__item {
				img {
					width: 900px;
					height: 500px;
				}
			}
		}
	}
	
	.line-img {
		max-width: 150px;
		border: 1px solid $borderColor;
	}
	.line-txt {
		font-feature-settings: 'palt';
		letter-spacing: 0.08em;
	}
}


