@charset "utf-8";
/* ============================================================ */
/* /news/ */
/* ============================================================ */
.news {
	&__sns {
		display: flex;
		align-items: center;
		padding: 10px 15px 5px;
		background: $gray;
		
		&__fb {
			margin: 0 10px 0 0;
		}
	}
	.entry__ttl + .news__sns {
		margin-bottom: 50px;
	}
	.entry__body + .news__sns {
		margin-top: 50px;
	}
	
	
	.entry {
		&__ttl {
			margin: 0 0 40px;

			&__inner {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			&__date {
				font-size: $m;
			}
		}
		&__body {
			img {
				max-width: 100%!important;
				height: auto !important;
			}
			
			a {
				@include transition;
				
				&:hover {
					@include opacity;
				}
			}
			
			span[style="color: #3366ff;"] {
				a {
					color: #3366ff;
				}
			}
			
			span[style="color: #0000ff;"] {
				a {
					color: #0000ff;
				}
			}
			
			span[style="color: #ff00ff;"] {
				a {
					color: #ff00ff;
				}
			}
								
			iframe {
				width: 100% !important;
			}
			
			.sns-inner {
				display: flex;
				justify-content: center;
				font-family: initial !important;
				
				.fb-page {
					display: block !important;
					width: 500px;
				}
			}
			
			table {
				width: 100%;

				th,
				td {
					padding: 13px 15px;
					background: $white;
					border: 1px solid $borderColor;
					text-align: left;
					vertical-align: middle;
					line-height: 1.6;
				}
				
				th {
					background: $lightColor;			
				}
				
				.bg-pink {
					background: $baseColor;
					color: $white;
				}
				.bg-pink-light {
					background: $lightColor;
				}
				.bg-beige {
					background: #f4f1ea;
				}
				.bg-white {
					background: $white;
				}
				.error {
					background: #fde9e9;
					color: #dd0000;
				}	
			}
			
			.midashi03 {
				position: relative;
				margin: 0 0 40px;
				padding: 20px 0 0;
				font-size: 2.6rem;
				@include font-bold;

				&::before,
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					height: 2px;
				}
				&::before {
					width: 100%;
					background: $borderColor;
				}
				&::after {
					width: 250px;
					background: $baseColor;
				}
			}
			.midashi04 {
				margin: 0 0 20px;
				padding: 0 0 15px;
				border-bottom: 1px solid $baseColor;
				font-size: $xxl;
				@include font-bold;
				line-height: 1.4;
			}
			.midashi05 {
				position: relative;
				margin: 0 0 20px;
				padding: 0 0 0 35px;
				font-size: $l;
				@include font-bold;
				line-height: 1.4;

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					width: 24px;
					height: 24px;
					background: url("/common/img/common/ico_ttl05.svg") 0 0 no-repeat;
				}
			}
			.midashi06 {
				margin: 0 0 15px;
				color: $baseColor;
				font-size: $l;
				@include font-bold;
				line-height: 1.4;
			}
		}
	}
	
	
	
	.no-cost {
		&__head {
			&__item:not(:empty) {
				width: 26.6%;
				text-align: center;
			}
			&__item:empty {
				width: 20%;
			}
		}
		&__body {
			&__text {
			}
		}
	}
	.kukaku-link {
		display: block;
		max-width: 300px;
		margin: 0 auto;
		text-decoration: none;
		line-height: 1.4;
		
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			text-align: center;
		}
		&__img {
			width: 100%;
			margin: 0 0 15px;
			@include transition;
		}
		&__txt {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $textColor;
			
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				display: inline-table;
				
				& > * {
					display: table-cell;
					vertical-align: middle;
				}
			}
			.ico-arrow {
				flex: 0 0 auto;
				margin: 0 8px 0 0;
			}
		}
		&:hover {
			.kukaku-link__img {
				@include opacity;
			}
			.kukaku-link__txt {
				color: $baseColor;
			}
		}
	}
}