@charset "utf-8";
/* ============================================================ */
/* iconフォント */
/* ============================================================ */
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?aa7g94');
  src:  url('/common/icon/fonts/icomoon.eot?aa7g94#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?aa7g94') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?aa7g94') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?aa7g94') format('woff'),
    url('/common/icon/fonts/icomoon.svg?aa7g94#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-line:before {
  content: "\e918";
  color: #2bac38;
}
.icon-facebook:before {
  content: "\e916";
  color: #2f6db6;
}
.icon-instagram:before {
  content: "\e917";
}
.icon-documents:before {
  content: "\e912";
}
.icon-calendar:before {
  content: "\e913";
}
.icon-mail:before {
  content: "\e914";
}
.icon-freecall:before {
  content: "\e911";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-beginner:before {
  content: "\e902";
}
.icon-customer:before {
  content: "\e903";
}
.icon-faq:before {
  content: "\e904";
}
.icon-home:before {
  content: "\e905";
}
.icon-home-top:before {
  content: "\e906";
}
.icon-hoyo:before {
  content: "\e907";
}
.icon-kukaku:before {
  content: "\e908";
}
.icon-map:before {
  content: "\e909";
}
.icon-nagano-clover:before {
  content: "\e90a";
}
.icon-service:before {
  content: "\e90b";
}
.icon-ueda-leaf:before {
  content: "\e90c";
}
.icon-filter_none:before {
  content: "\e3e0";
}
.icon-search:before {
  content: "\f002";
}
.icon-check:before {
  content: "\f00c";
}
.icon-home1:before {
  content: "\f015";
}
.icon-exclamation-triangle:before {
  content: "\f071";
}
.icon-phone:before {
  content: "\f095";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-file-pdf:before {
  content: "\e915";
}
.icon-close:before {
  content: "\e910";
}
.icon-minus:before {
  content: "\e90e";
}
.icon-menu:before {
  content: "\e90f";
}
.icon-plus:before {
  content: "\e90d";
}