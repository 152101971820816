@charset "utf-8";
/* ============================================================ */
/* /customer/ */
/* ============================================================ */
.customer {
	.entry {
		&__ttl {
			margin: 0 0 50px;

			&__inner {
				display: flex;
				align-items: center;
				
				.label {
					margin: 0 10px 0 0;
				}
			}
		}
		&__body {
			img {
				max-width: 100%!important;
				height: auto !important;
			}
			
			a {
				@include transition;
				
				&:hover {
					@include opacity;
				}
			}
			
			span[style="color: #3366ff;"] {
				a {
					color: #3366ff;
				}
			}
			
			span[style="color: #0000ff;"] {
				a {
					color: #0000ff;
				}
			}
								
			iframe {
				width: 100% !important;
			}
			
			table {
				width: 100%;

				th,
				td {
					padding: 13px 15px;
					background: $white;
					border: 1px solid $borderColor;
					text-align: left;
					vertical-align: middle;
					line-height: 1.6;
				}
				
				th {
					background: $lightColor;			
				}
				
				.bg-pink {
					background: $baseColor;
					color: $white;
				}
				.bg-pink-light {
					background: $lightColor;
				}
				.bg-beige {
					background: #f4f1ea;
				}
				.error {
					background: #fde9e9;
					color: #dd0000;
				}	
			}
			
			.midashi02 {
				border: 1px solid #F8DCE5;
				border-top: 4px solid $baseColor;
				background: #FFF6F9;
				padding: 10px 25px 15px;
				border-radius: 0 0 3px 3px;
			}
			.midashi03 {
				position: relative;
				margin: 0 0 30px;
				padding: 20px 0 0;
				font-size: 2.6rem;
				@include font-bold;

				&::before,
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					height: 2px;
				}
				&::before {
					width: 100%;
					background: $borderColor;
				}
				&::after {
					width: 250px;
					background: $baseColor;
				}
			}
			.midashi04 {
				margin: 0 0 20px;
				padding: 0 0 15px;
				border-bottom: 1px solid $baseColor;
				font-size: $xxl;
				@include font-bold;
				line-height: 1.4;
			}
			.midashi05 {
				position: relative;
				margin: 0 0 20px;
				padding: 0 0 0 35px;
				font-size: $l;
				@include font-bold;
				line-height: 1.4;

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					width: 24px;
					height: 24px;
					background: url("/common/img/common/ico_ttl05.svg") 0 0 no-repeat;
				}
			}
			.midashi06 {
				margin: 0 0 15px;
				color: $baseColor;
				font-size: $l;
				@include font-bold;
				line-height: 1.4;
			}
			
			.block {
				margin: 0 0 20px 0;
			}

			.txt_name {
				text-align: right;
			}
		}
		&__old {
			margin: 0 0 20px;
		}
		&__old-img-block {
			text-align: center;
			margin-bottom: 20px;
		}
	}
	
	.customer-btn {
		text-align: center;
		border-top: 1px dotted $borderColor;
		padding: 30px 0 0;
		margin: 70px 0 0;
	}
	
	.customer-sns {
		margin: 40px 0 0;
		
		&__inner {
			justify-content: center;
			display: flex;
			font-family: initial !important;
			
			.fb-page {
				display: block !important;
				width: 500px;
			}
		}
	}	
}

.customer_detail {
	.main {
		margin: 0 auto !important;
		
		@media screen and (min-width:  767.5px) and (max-width:1350px) {
			width: 100% !important;
			margin-right: auto !important;
		}
	}
}
