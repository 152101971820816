@charset "utf-8";
/* ============================================================ */
/* /customer/ */
/* ============================================================ */
.customer {
	.entry {
		&__ttl {
			margin: 0 0 30px;

			&__inner {
				display: flex;
				align-items: center;
				
				.label {
					margin: 0 5px 0 0;
				}
			}
		}
		&__body {
			img {
				max-width: 100% !important;
				height: auto !important;
			}
			
			.instagram-media {
				width: 414px !important;
				max-width: 100% !important;
				min-width: initial !important;
			}
			
			span[style="color: #3366ff;"] {
				a {
					color: #3366ff;
				}
			}
			
			span[style="color: #0000ff;"] {
				a {
					color: #0000ff;
				}
			}
						
			iframe {
				width: 100%;
			}
			
			table {
				width: 100%;
				border: 1px solid $borderColor;
				table-layout: fixed;
				line-height: 1.6;
				word-break: break-all;
				word-wrap: break-word;

				th,
				td {
					padding: 10px;
					background: $white;
					border: 1px solid $borderColor;
					text-align: left;
					vertical-align: middle;
				}
				
				th {
					background: $lightColor;			
				}
				
				.bg-pink {
					background: $baseColor;
					color: $white;
				}
				.bg-pink-light {
					background: $lightColor;
				}
				.bg-beige {
					background: #f4f1ea;
				}
				.error {
					background: #fde9e9;
					color: #dd0000;
				}
			}
			
			.midashi02 {
				border: 1px solid #F8DCE5;
				border-top: 4px solid $baseColor;
				background: #FFF6F9;
				padding: 5px 15px 10px;
				border-radius: 0 0 3px 3px;
			}
			.midashi03 {
				position: relative;
				margin: 0 0 20px;
				padding: 15px 0 0;
				font-size: $l;
				line-height: 1.4;
				@include font-bold;

				&::before,
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					height: 2px;
				}
				&::before {
					width: 100%;
					background: $borderColor;
				}
				&::after {
					width: 30%;
					background: $baseColor;
				}
			}
			.midashi04 {
				margin: 0 0 15px;
				padding: 0 0 10px;
				border-bottom: 1px solid $baseColor;
				font-size: $l;
				@include font-bold;
				line-height: 1.4;
			}
			.midashi05 {
				position: relative;
				margin: 0 0 15px;
				padding: 0 0 0 30px;
				font-size: $m;
				@include font-bold;
				line-height: 1.4;

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					width: 24px;
					height: 24px;
					background: url("/common/img/common/ico_ttl05.svg") 0 0 no-repeat;
				}
			}
			.midashi06 {
				margin: 0 0 15px;
				color: $baseColor;
				font-size: $m;
				@include font-bold;
				line-height: 1.4;
			}
		}
		&__old {
			margin: 0 0 20px;
		}
		&__old-img-block {
			text-align: center;
			margin-bottom: 20px;
		}
	}	
	
	.customer-btn {
		text-align: center;
		border-top: 1px dotted $borderColor;
		padding: 25px 0 0;
		margin: 35px 0 0;
	}
	
	.customer-sns {
		margin: 20px 0 0;
		
		&__inner {
			font-family: initial !important;
			
			.fb-page {
				width: 100%;

				* {
					width: 100% !important;
				}
			}
		}
	}
	
}