@charset "utf-8";
/* ============================================================ */
/* /faq/ */
/* ============================================================ */
.faq {
	&__q {
		position: relative;
		padding: 25px 55px;
		border-bottom: 1px dotted $borderColor;
		
		/*&::after {
			position: absolute;
			top: 50%;
			right: 10px;
			content: "\f107";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $xxxl;
			transform: translateY(-50%);
		}*/
		/*&:hover,
		&.active {
			color: $baseColor;
		}*/
		/*&.active {
			&::after {
				transform: translateY(-50%) rotate(180deg);
			}
		}*/
		
		&__link {
			display: block;
			
			&::before {
				position: absolute;
				top: 20px;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				content: 'Q';
				background: $white;
				border: 1px solid $baseColor;
				color: $baseColor;
				font-size: $xl;
				@include circle(40);

				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					height: 35px;
					padding-top: 5px;
				}
			}

			&:hover {
				.faq {
					&__q {
						&__link {
							&::before {
								background: $baseColor;
								border: 1px solid $baseColor;
								color: $white;
							}
						}
					}
				}
			}
		}
	}
	
	&-detail {
		&__q {
			padding: 25px 25px 25px 75px;
			background: $lightColor;
			border-top: 1px dotted $borderColor;
			border-bottom: 1px dotted $borderColor;
			position: relative;

			&::before {
				position: absolute;
				top: 20px;
				left: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				content: 'Q';
				background: $white;
				border: 1px solid $baseColor;
				color: $baseColor;
				font-size: $xl;
				@include circle(40);

				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					height: 35px;
					padding-top: 5px;
				}
			}
		}
		
		&__a {
			position: relative;
			padding: 35px 25px 40px 75px;
			border-bottom: 1px dotted $borderColor;

			&::before {
				position: absolute;
				top: 30px;
				left: 20px;
				content: 'A';
				display: flex;
				align-items: center;
				justify-content: center;
				background: $baseColor;
				color: $white;
				font-size: $xl;
				@include circle(40);

				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					height: 35px;
					padding-top: 5px;
				}
			}
		}
	}
	
	&-list {
		margin: 80px 0 0;
		
		.ttl03 {
			margin: 0 0 20px;
		}
	}
	
	&-detail {
		&__a {
			&__entry {
				img {
					max-width: 100%!important;
					height: auto !important;
				}

				a {
					@include transition;

					&:hover {
						@include opacity;
					}
				}

				iframe {
					width: 100% !important;
				}

				table {
					width: 100%;

					th,
					td {
						padding: 13px 15px;
						background: $white;
						border: 1px solid $borderColor;
						text-align: left;
						vertical-align: middle;
						line-height: 1.6;
					}

					th {
						background: $lightColor;			
					}

					.bg-pink {
						background: $baseColor;
						color: $white;
					}
					.bg-pink-light {
						background: $lightColor;
					}
					.bg-beige {
						background: #f4f1ea;
					}
					.error {
						background: #fde9e9;
						color: #dd0000;
					}	
				}

				.midashi03 {
					position: relative;
					margin: 0 0 40px;
					padding: 20px 0 0;
					font-size: 2.6rem;
					@include font-bold;

					&::before,
					&::after {
						position: absolute;
						top: 0;
						left: 0;
						content: '';
						height: 2px;
					}
					&::before {
						width: 100%;
						background: $borderColor;
					}
					&::after {
						width: 250px;
						background: $baseColor;
					}
				}
				.midashi04 {
					margin: 0 0 20px;
					padding: 0 0 15px;
					border-bottom: 1px solid $baseColor;
					font-size: $xxl;
					@include font-bold;
					line-height: 1.4;
				}
				.midashi05 {
					position: relative;
					margin: 0 0 20px;
					padding: 0 0 0 35px;
					font-size: $l;
					@include font-bold;
					line-height: 1.4;

					&::before {
						position: absolute;
						top: 0;
						left: 0;
						content: '';
						width: 24px;
						height: 24px;
						background: url("/common/img/common/ico_ttl05.svg") 0 0 no-repeat;
					}
				}
				.midashi06 {
					margin: 0 0 15px;
					color: $baseColor;
					font-size: $l;
					@include font-bold;
					line-height: 1.4;
				}
			}
		}
	}
	
	.faq-btn {
		text-align: center;
		margin: 40px 0 0;
	}

}