@charset "utf-8";
/* ============================================================ */
/* /reservation/ */
/* ============================================================ */
.reservation {
	
	.howto {
		margin: 0 auto;
		width: 60%;
		
		&__item {
			margin: 0 15px;
			text-align: center;
			
			.btn {
				width: 100%;
				min-width: auto!important;
			}
			
			&__tel {
				font-size: 2.8rem;
				@include font-bold;
				
				&__ico {
					color: $freecall;
				}
				&__link {
					color: $baseColor;
				}
			}
		}
	}
	
	
	.features {
		margin-left: -10px;
		margin-right: -10px;
		
		&__item {
			display: flex;
			flex-direction: column;
			width: calc((100% - 61px) / 3);
			margin: 0 10px 20px;
			background: $lightColor;
			
			&__img {
				margin: 0 0 25px;
			}
			.ttl03 {
				font-size: $xl;
			}
			&__txt {
				margin: 0 20px 25px;
			}
		}
	}
	
	.ui-datepicker {
		width: 300px;
		font-size: $s;
		@include font-normal;
	}
	// header 部分
	.ui-widget-header {
		background: $baseColor;
		color: $white;
	}
	a.ui-datepicker-prev,
	a.ui-datepicker-next {
		cursor: pointer;
	}
	.ui-datepicker-prev,
	.ui-datepicker-next {
		.ui-icon {
			background: none;
			@include transition;
			
			&::before {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				color: $white;
				font-family: 'icomoon';
				font-size: $m;
				text-indent: 0;
				@include centering-elements(true, true);
				@include radius(3);
			}
		}
	}
	.ui-datepicker-prev {
		.ui-icon::before {
			content: "\f104";
		}
	}
	.ui-datepicker-next {
		.ui-icon::before {
			content: "\f105";
		}
	}
	.ui-datepicker-prev-hover,
	.ui-datepicker-next-hover {
		top: 2px;
		border: none;
		background: $white;
		
		.ui-icon {
			background: none;
			&::before {
				color: $baseColor;
			}
		}
	}
	.ui-datepicker-prev-hover {
		left: 2px;
	}
	.ui-datepicker-next-hover {
		right: 2px;
	}	
	// セル部分
	.ui-datepicker td span,
	.ui-datepicker td a {
		padding: .6em;
	}
	.ui-state-default {
		border: 1px solid $borderColor;
		background: $white;
    color: $textColor;
		text-align: center;
		@include font-normal;
		@include radius(2);
		
		&:hover,
		&.ui-state-active {
			background: $baseColor;
			color: $white;
		}
	}	
	span.ui-state-default {
		background: $gray;
	}
	
}
