@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.contents {
		margin: 150px 0 0;
		
		@media screen and (max-width: 1390px) {
			margin: 140px 0 0;
		}
	}
	
	/* --------------------------------------------------- */
	/* index-visual */
	/* --------------------------------------------------- */
	.index-visual {
		width: 100%;
		height: 560px;
		position: relative;
		
		&__inner {
			height: 560px;
			
			&__slider {
				height: 560px;
				overflow: hidden;
				position: relative;
				
				.slick-slide {
				}
				
				.slick-prev {
					position: absolute;
					top: 50%;
					left: 5.5%;
					transform: translate(0, -50%);
					z-index: 2;
					color: $white;
					border: none;
					background: rgba($white,0.85);
					box-shadow: 0 0 15px 0 rgba(0,0,0,1);
					display: flex;
					align-items: center;
					justify-content: center;
					
					@media screen and (max-width: 1670px) {
						left: 3%;
					}
					
					&::before {
						color: #000 !important;
					}
					
					&:hover {
						&::before {
							color: #000 !important;
							opacity: 1;
						}
					}				
				}
				
				.slick-next {
					position: absolute;
					top: 50%;
					right: 5.5%;
					transform: translate(0, -50%);
					z-index: 2;
					color: $white;
					border: none;
					background: rgba($white,0.85);
					box-shadow: 0 0 15px 0 rgba(0,0,0,1);
					display: flex;
					align-items: center;
					justify-content: center;
					
					@media screen and (max-width: 1670px) {
						right: 3%;
					}
					
					&::before {
						color: #000 !important;
					}
					
					&:hover {
						&::before {
							color: #000 !important;
							opacity: 1;
						}
					}	
				}
				
				&__item {
					position: relative;
					
					&__link {
						color: $textColor;
						display: block;
					}
					
					&__text {
						height: 100%;
						width: 100%;
						z-index: 3;
						padding: 50px 60px 60px;
						position: absolute;
						left: 0;
						bottom: 0;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: flex-end;
						text-align: center;
						
						@media screen and (max-width: 1180px) {
							padding: 40px;
						}
						
						&__cap {
							margin: 0 0 15px;

							&__bg {
								display: inline-block;
								margin: 0 0 5px;
								padding: 0 0 0 2em;
								line-height: 140%;
								font-size: $xxxl;
								@include font-bold;
								color: $white;
								text-shadow: 0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5),0 0 15px rgba(#000, 0.5);

								&--indent {
									text-align: left;
									text-indent: -2em;
								}
							}
						}
					}
					
					&__img {
						overflow: hidden;
						height: 100%;
					}
					
					.slick-list ,
					.slick-track {
						height: 560px;
					}

					.object-fit-img {
						height: 560px;
						object-fit: cover;
						object-position: center center;
						font-family: 'object-fit: cover; object-position: center center;'
					}
				}
			}
			
			.slick-dots {
				margin: -50px 0 0;
				position: relative;
				z-index: 2;
			}
		}
	}
	
	// visual09：永代供養墓（4名）用スタイル
	.kuyou-four {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		padding: 50px 25px;

		&__inner {
			position: relative;
			display: flex;
			width: 100%;
			max-width: 1520px;
		}
		&__txt2 {
			margin-left: auto;
			align-self: center;
		}
		&__btn {
			position: absolute;
			left: 0;
			bottom: 20px;
		}
		&__sp {
			display: none;
		}
	}
	
	// visual11：お墓の相談会用スタイル
	.grave-consultation {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		padding: 50px 25px;

		&__inner {
			position: relative;
			display: flex;
			width: 100%;
			max-width: 1520px;
		}
		&__txt {
			margin-left: auto;
			align-self: center;
		}
		&__sp {
			display: none;
		}
	}
	
	
	/* --------------------------------------------------- */
	/* index-bnr */
	/* --------------------------------------------------- */
	.index-bnr {
		margin: 20px auto 0;
		text-align: center;
		max-width: 330px;
		
		&__item {
		}
	}
	
	
	/* --------------------------------------------------- */
	/* index-top-block */
	/* --------------------------------------------------- */
	.index-top-block {
		width: 100%;
		max-width: 1520px;
		display: flex;
		margin: 0 auto;
		
		@media screen and (max-width: 1580px) {
			padding: 0 25px 0;
		}
	}
	
	
	/* --------------------------------------------------- */
	/* index-top-bnr */
	/* --------------------------------------------------- */
	.index-top-bnr {
		margin: 0 0 0 25px;
	}
	
	
	/* --------------------------------------------------- */
	/* index-important */
	/* --------------------------------------------------- */
	.index-important {
		flex: 1;
		margin: 70px auto 0;
		padding: 15px 25px 25px;
		
		
		&__ttl {
			color: $baseColor;
			text-align: center;
			border-bottom: 1px dotted rgba($baseColor, 0.5);
			padding: 0 0 12px;
			margin: 0 0 20px;
			font-size: $xxl;
			@include font-bold;
		}
		
		&__list {
			&__item {
				margin: 0 0 5px;
				
				&:last-of-type {
					margin: 0;
				}
				
				.icon-angle-right {
					color: $baseColor;
				}
				
				&__link {
					margin: 0 0 0 5px;
				}
			}
		}
	}
	
	
	/* --------------------------------------------------- */
	/* index-news */
	/* --------------------------------------------------- */
	.index-news {
		border-top: 5px solid #ffebec;
		padding: 50px 0 0;
		margin: 0 0 70px;
		
		&__btn {
			margin: 30px auto 0;
			text-align: center;
		}
		
		&__ttl {
			margin: 0 0 20px;
			text-align: center;
			
			.ttl02 {
				font-size: 2.9rem;
				margin: 0 0 5px;
			}
			
			&__sns {
				display: flex;
				justify-content: center;
				position: relative;
				z-index: 100;

				&__link {
					margin: 0 5px;
					font-size: 3.0rem;
					text-decoration: none;
					color: #000;
				}
			}
		}
		
		&__inner {
			width: min(1000px, 100% - 50px);
			margin: 0 auto;
		}
		
		&-list {
			border-top: 1px dotted $borderColor;
			&__item {
				width: 100%;
				border-bottom: 1px dotted $borderColor;
			}
			&__link {
				display: flex;
				text-decoration: none;
				color: $textColor;
				padding: 15px 0;
				&:hover {
					.index-news-list__ttl {
						text-decoration: none;
					}
				}
			}
			&__date {
				flex: 0 0 auto;
				min-width: 8em;
				margin-right: 20px;
			}

			&__category {
				flex: 0 0 auto;
				min-width: 14%;
				margin-right: 30px;
				text-align: center;
			}

			&__ttl {
				flex: 1 1 auto;
				text-decoration: underline;
				color: $baseColor;
				line-height: 1.6;
				word-break: break-all;
				font-size: $l;
				@include transition;
			}

			
		}
		
	}
	
	/* --------------------------------------------------- */
	/* index-kukaku */
	/* --------------------------------------------------- */
	.index-kukaku {
		background: url("/common/img/index/bg_kukaku.jpg") 0 0 repeat;
		margin-top: 70px;
		padding: 70px 0 90px;
		position: relative;
		
		@media screen and (max-width: 1580px) {
			padding: 70px 25px 90px;
		}
		
		&::before {
			background: url("/common/img/index/bg_kukaku_leaf.png") top center no-repeat;
			background-size: contain;
			width: 1920px;
			height: 2119px;
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}
		
		&__inner {
			position: relative;
			z-index: 2;
			max-width: 1520px;
			margin: 0 auto;
			
			.ttl02 {
				text-align: center;
			}
			
			&__list {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -25px 65px;
				
				@media screen and (max-width: 1580px) {
					margin: 0 -15px 65px;
				}
				
				&:last-of-type {
					margin: 0 -25px;
					
					@media screen and (max-width: 1580px) {
						margin: 0 -15px;
					}
				}
				
				&__item {
					width: calc((100% - 101px) / 2);
					margin: 0 25px;
					
					@media screen and (max-width: 1580px) {
						width: calc((100% - 61px) / 2);
						margin: 0 15px;
					}
					
					&__block {
						background: url("/common/img/index/bg_kukaku_flower02.png") right bottom no-repeat $white;
						@include radius(3);
						box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
						padding: 50px 50px 45px;
						height: 100%;
						
						&__link {
							text-decoration: none;
							color: $textColor;
							text-align: center;
							height: 100%;
							display: flex;
							flex-direction: column;

							&__top {
								margin: 0 0 20px;
								flex: 1 1 auto;

								.ttl03 {
									@media screen and (max-width: 1580px) {
										font-size: $xxl;
									}
								}

								&__img {
									margin: 0 0 30px;
								}

								&__text {		
									text-align: left;
									
									@media screen and (max-width: 1580px) {
										font-size: $m;
									}
								}
							}
							.ico-arrow {
								margin: 0 auto;
							}

						}
					}
				}
			}
			
			.kukaku-eidai {
				margin: 0 -12.5px 50px;
				
				.index-kukaku {
					&__inner {
						&__list {
							&__item {
								width: calc((100% - 76px) / 3);
								margin: 0 12.5px;
								
								&__block {
									padding: 40px 40px 35px;

									@media screen and (max-width: 1580px) {
										padding: 30px 25px 25px;
									}
									
									&__link {
										&__text {
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-guide */
	/* --------------------------------------------------- */
	.index-guide {
		margin-top: 70px;
		padding: 0 0 100px;
		position: relative;
		
		@media screen and (max-width: 1580px) {
			padding: 0 25px 100px;
		}
		
		&::before {
			background: url("/common/img/index/bg_guide_left.png") top left no-repeat;
			background-size: contain;
			width: 35%;
			height: 35%;
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}
		
		&::after {
			background: url("/common/img/index/bg_guide_right.png") top right no-repeat;
			background-size: contain;
			width: 35%;
			height: 35%;
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
		}
		
		&__inner {
			position: relative;
			z-index: 2;
			max-width: 1520px;
			margin: 0 auto;
			padding: 90px 0 0;
			
			@media screen and (max-width: 1580px) {
				padding: 50px 0 0;
			}
			
			.ttl02 {
				text-align: center;
			}
			
			&__list {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -22px;
				
				@media screen and (max-width: 1020px) {
					margin: 0 -15px;
				}
				
				&__item {
					margin: 0 22px 50px;
					width: calc((100% - 176px) / 4);
					
					@media screen and (max-width: 1020px) {
						margin: 0 15px 25px;
						width: calc((100% - 120px) / 4);
					}
					
					&__link {
						display: block;
						text-decoration: none;
						
						&:hover {
							.index-guide {
								&__inner {
									&__list {
										&__item {
											&__link {
												&__img {
													@include opacity;
													@include transition;
												}
												
												&__text {
													color: $baseColor;
												}
											}
										}
									}
								}
							}
						}
						
						&__img {
							position: relative;
							margin: 0 0 20px;
						}
						
						&__text {
							display: flex;
							align-items: center;
							justify-content: center;
							color: $textColor;
							font-size: $l;
							@include font-bold;
							line-height: 1.4;
							
							@media screen and (max-width: 1580px) {
								font-size: $m;
							}
							
							&__ttl {
								max-width: calc(100% - 44px);
								text-align: left;
							}
							
							.ico-arrow {
								margin: 0 10px 0 0;
							}
						}
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-faq */
	/* --------------------------------------------------- */
	.index-faq {
		margin-top: 70px;
		position: relative;
		background: #FFF8FA;
				
		&__block {
			position: relative;
			z-index: 2;
			background: $white;
			box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
			width: calc(100% - 200px);
			margin: -3% 0 0;
			padding: 60px 0;
			
			@media screen and (max-width: 1457px) {
				width: calc(100% - 80px);
			}
			
			@media screen and (max-width: 1160px) {
				width: calc(100% - 30px) !important;
				padding: 45px 0 40px;
			}
			
			&__inner {
				max-width: 1520px;
				margin: 0 0 0 auto;
				padding: 0 80px 0 0;
				background: url("/common/img/index/bg_faq_inner.png") left bottom no-repeat;
				background-size: 20%;
				display: flex;
				justify-content: space-between;
				
				@media screen and (max-width: 1765px) {
					max-width: auto !important;
					width: calc(100% - 100px);
					padding: 0;
					margin: 0 auto;
				}
				
				@media screen and (max-width: 940px) {
					width: calc(100% - 90px) !important;
				}

				&__ttl {
					width: 27%;
					margin: 0 90px 0 0;
					
					@media screen and (max-width: 1457px) {
						margin: 0 60px 0 0;
					}
					
					@media screen and (max-width: 1160px) {
						margin: 0 40px 0 0 !important;
					}
					
					.btn {
						@media screen and (max-width: 1220px) {
							min-width: auto !important;
							width: 100%;
						}
					}
				}
				
				&__list {
					width: calc((100% - 27%) - 90px);
					display: flex;
					justify-content: space-between;
					
					@media screen and (max-width: 1457px) {
						width: calc((100% - 27%) - 60px);
					}
					
					@media screen and (max-width: 1160px) {
						width: calc((100% - 27%) - 40px) !important;
					}
					
					&__block {
						width: calc((100% - 60px) / 2);
							
						@media screen and (max-width: 1460px) {
							width: calc((100% - 40px) / 2);
						}
						
						@media screen and (max-width: 1160px) {
							width: calc((100% - 30px) / 2) !important;
						}
						
						&__item {
							margin: 0 0 20px;
							
							&__btn {
								text-align: center;
								
								.btn {
									width: 60%;
									min-width: auto;
									
									@media screen and (max-width: 1085px) {
										width: 80%;
									}
								}
							}
							
							&__question {
								margin: 0 0 20px;
								
								&__detail {
									border-bottom: 1px dotted $borderColorBeige;
									padding: 15px 20px;
									
									.icon-angle-right {
										margin: 0 10px 0 0;
									}
									
									&__link {
										text-decoration: none;
										@include font-bold;
										
										&:hover {
											text-decoration: underline;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-message */
	/* --------------------------------------------------- */
	.index-message {
		margin-top: 70px;
		padding: 20px 0 90px;
		position: relative;
		
		.ttl02 {
			text-align: center;
			margin: 0 0 40px;
		}
		&__inner {
			position: relative;
			margin: 0 auto;
			display: flex;
			flex-direction: row-reverse;
		}
		&__right {
			width: 58.339%;
			margin: 90px 0 0;
			position: relative;
			z-index: 1;
			
			&::before {
				content: "";
				width: 260px;
				height: 400px;
				background: url("/common/img/index/bg_message_right01.png") 0 0 no-repeat;
				display: block;
				position: absolute;
				top: -215px;
				left: 60%;
			}
		}
		&__pic01 {
			z-index: 1;
			position: relative;
			
			.__elem {
				width: 100%;
			}
			&::after {
				content: "";
				width: 767px;
				height: 474px;
				background: url("/common/img/index/bg_message_right02.png") 0 0 no-repeat;
				display: block;
				position: absolute;
				bottom: -215px;
				right: -18%;
				z-index: -1;
			}
		}
		&__left {
			width: 48.043%;
			margin: 0 -6.382% 0 0;
			position: relative;
			
			&::before {
				content: "";
				width: 188px;
				height: 193px;
				background: url("/common/img/index/bg_message_left01.png") 0 0 no-repeat;
				display: block;
				position: absolute;
				top: -110px;
				right: 60%;
			}
		}
		&__txt {
			font-size: $xxl;
			background: #FEF1F6;
			text-align: center;
			padding: 90px 0;
			
			.__txt01 {
				background: url("/common/img/index/ico_message.png") center bottom no-repeat;
				margin: 0 0 30px;
				padding: 0 0 70px;
			}
		}
		&__pic02 {
			margin: 45px 0 0;
			padding: 0 13.5% 0 0;
			text-align: right;
			
			.__elem {
				width: 66.142%;
				margin: 0 7% 0 0;
			}
		}
	}
	
	
	/* --------------------------------------------------- */
	/* index-feature */
	/* --------------------------------------------------- */
	.index-feature {
		padding: 50px 0 80px;
		position: relative;
		
		@media screen and (max-width: 1580px) {
			padding: 70px 25px 90px;
		}
		.ttl02 {
			text-align: center;
			margin: 0 0 45px;
			
			&__num {
				margin: 0 -15px 0 5px;
			}
		}
		
		&__inner {
			max-width: 1520px;
			margin: 0 auto;
			position: relative;
		}
		&-list {
			&-lg {
				display: flex;
				margin: 0 -15px;
				
				&__item {
					width: calc((100% - 90px) / 3);
					margin: 0 15px;
				}
				&__ttl {
					color: $baseColor;
					@include font-bold;
					font-size: $xxxl;
					text-align: center;
					margin: 0 0 15px;
					background: url("/common/img/index/bg_feature.png") right bottom no-repeat;
					position: relative;
					
					@media screen and (max-width: 1580px) {
						font-size: $xl;
					}
				}
				&__num {
					height: 150px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: -75px 0 -20px;
					position: relative;
					background: url("/common/img/index/bg_feature_num.png") center center no-repeat;
				}
				&__main {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				&__txt {
					@media screen and (max-width: 1580px) {
						font-size: $m;
					}
				}
			}
			&-sm {
				display: flex;
				margin: 50px -12.5px 0;
				
				&__item {
					width: calc((100% - 100px) / 4);
					margin: 0 12.5px;
				}
				&__ttl {
					color: $baseColor;
					@include font-bold;
					font-size: $xxl;
					text-align: center;
					margin: 0 0 15px;
					background: url("/common/img/index/bg_feature.png") right bottom /65px auto no-repeat;
					position: relative;
					
					@media screen and (max-width: 1580px) {
						font-size: $l;
					}
				}
				&__num {
					height: 150px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: -75px 0 -30px;
					position: relative;
					background: url("/common/img/index/bg_feature_num.png") center center no-repeat;
				}
				&__main {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				&__txt {
					@media screen and (max-width: 1580px) {
						font-size: $m;
					}
				}
			}
		}
	}
	
	
	/* --------------------------------------------------- */
	/* index-support */
	/* --------------------------------------------------- */
	.index-support {
		background: url("/common/img/index/bg_news.png") top left no-repeat;
		margin: 70px 0 0;
		text-align: center;
		
		&__btn {
			text-align: center;
		}
		
		&__inner {
			width: min(1520px, 100% - 50px);
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
		}
		
		&-item {
			width: calc((100% - 60px) / 3);
			margin: 0 30px 40px 0;
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
			text-decoration: none;
			border-radius: 3px;
			overflow: hidden;
			@include transition;
			&:nth-of-type(3n) {
				margin-right: 0;
			}
			&:hover {
				@include opacity;
			}
			&__ttl {
				color: $white;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 15px 10px;
				font-size: $m;
				line-height: 1.4;
				&._bg-01_ {
					background: url("/common/img/index/bg_support_01.png") no-repeat center center;
					background-size: cover;
					text-shadow: 0 0 10px #ea6042;
				}
				&._bg-02_ {
					background: url("/common/img/index/bg_support_02.png") no-repeat center center;
					background-size: cover;
					text-shadow: 0 0 10px #649d35;
				}
				&._bg-03_ {
					background: url("/common/img/index/bg_support_03.png") no-repeat center center;
					background-size: cover;
					text-shadow: 0 0 10px #ec5178;
				}
				&._bg-04_ {
					background: url("/common/img/index/bg_support_04.png") no-repeat center center;
					background-size: cover;
					text-shadow: 0 0 10px #be6b38;
				}
				&._bg-05_ {
					background: url("/common/img/index/bg_support_05.png") no-repeat center center;
					background-size: cover;
					text-shadow: 0 0 10px #ef8c01;
				}
				.__lg {
					font-size: 2.6rem;
					@include font-bold;
				}
			}
		}
		
	}
	
	
	/* --------------------------------------------------- */
	/* index-consultation-bnr */
	/* --------------------------------------------------- */
	.index-consultation-bnr {
		max-width: 1250px;
		background: #FFF6F9;
		margin: 70px auto 0;
		padding: 40px 25px;
		
		&__link {
			width: 100%;
			max-width: 1000px;
			display: block;
			margin: 0 auto;
		}
	}
	
	
	
	/* --------------------------------------------------- */
	/* index-movie */
	/* --------------------------------------------------- */
	.index-movie {
		background: #ffebec;
		margin-top: 70px;
		padding: 70px 0 90px;
		
		@media screen and (max-width: 1580px) {
			padding: 70px 25px 90px;
		}
		
		&__inner {
			position: relative;
			z-index: 2;
			max-width: 1520px;
			margin: 0 auto;
			
			.ttl02 {
				text-align: center;
				margin-bottom: 40px;
			}
		}
		
		&__box {
			max-width: 1000px;
			margin: 0 auto;
		}
	}
	

	
}