@charset "utf-8";
/* ============================================================ */
/* /access/ */
/* ============================================================ */
.access {
	.map-canvas {
		width: 100%;
		height: 450px;
		margin: 0 0 15px;
	}
}