@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	position: fixed;
	background: $white;
	z-index: 500;
	width: 100%;
	
	.header-inner {
		position: relative;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 5px 0 0;
		
		&__logo {
			width: 70%;
			margin: 0 0 0 15px;
			
			&__text {
				font-size: $xxxs;
				color: #747474;
				margin: 0;
			}
			
			&__link {
				display: block;
				width: 185px;
			}
		}
		
		&__menu {	
			display: flex;
			margin: 0 15px 0 0;
			
			&__btn {
				width: 60px;
				height: 60px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				font-size: $xs;
				@include font-bold;
				background: $baseColor;
				color: $white;
				@include radius(3);
				line-height: 1.2;
				
				.icon-menu {
					font-size: 2.6rem;
					margin: 0 0 -2px;
				}
			}
		}
		
		&__tab {
			width: 100%;
			margin: 5px 0 0;
			padding: 5px 10px 0;
			display: flex;
			border-bottom: 1px solid $borderColorPink;
			border-top: 1px solid $borderColorPink;
			background: $lightColor;
			
			&__item {	
				width: 38%;
				
				/*&:first-of-type {
					width: 24%;
				}*/
				
				.ueda & {
					.angel-ueda {
						background: $white;
						border: 1px solid $borderColorPink;
						border-bottom: none;
						border-radius: 3px 3px 0 0;
						@include font-bold;
						position: relative;
						
						&::after {
							position: absolute;
							bottom: -3px;
							left: 0;
							display: block;
							width: 100%;
							height: 4px;
							content: "";
							background: $white;
						}		
					}

					.angel-home {
						border-right: none;
						color: #4084D2;
						
						&:before {
							position: absolute;
							top: 8px;
							left: 0;
							content: "";
							display: block;
							width: 1px;
							height: calc(100% - 21px);
							border-left: 1px solid $borderColor;
							box-sizing: border-box;
						}		
						
						&:hover {
							&:before {
								border-left: none;
							}
						}
					}

					.angel-nagano {
						color: #208415;
						position: relative;
						
						&:before {
							position: absolute;
							top: 8px;
							right: 0;
							content: "";
							display: block;
							width: 1px;
							height: calc(100% - 21px);
							border-right: 1px solid $borderColor;
							box-sizing: border-box;
						}
						
						&::after {
							border-left: none;
						}
						
						&:hover {
							border-left: none;
							
							&:before {
								border-right: none;
							}
						}
					}
				}
				
				&__link {
					display: flex;
					width: 100%;
					height: 100%;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					text-align: center;
					padding: 5px;
					text-decoration: none;
					position: relative;
										
					&:hover {
						background: $white;
						border: 1px solid $borderColorPink;
						border-bottom: none;
						border-radius: 3px 3px 0 0;
						@include font-bold;
						position: relative;
						
						&::after {
							position: absolute;
							bottom: -3px;
							left: 0;
							display: block;
							width: 100%;
							height: 4px;
							content: "";
							background: $white;
						}		
					}
					
					&__ico {
						font-size: $l;
						line-height: 1.2;
					}
					
					&__text {
						line-height: 1.4;
						font-size: $xxxs;
						
						&__sub {
							font-size: $xxxs;
							color: $textColor !important;
							display: block;
						}
					}
				}
			}
		}
	}
	
	.header-gnav {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		padding: 0 0 20px;
		background: $baseColor;
		opacity: 0;
		overflow-y: auto;
		display: none;
		overflow: hidden;
		animation: menufade 0.5s ease;

		&.open {
			z-index: 800;
			opacity: 1;
			display: block;
			animation: menufade 0.5s ease;
		}
		
		&__inner {
			padding: 10px 15px 0;
			
			&__btn {
				&--top {
					color: $white;
					width: 60px;
					height: 60px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: $xxxl;
					margin: 0 0 0 auto;
					border: 1px solid rgba($white, 0.3);
					@include radius(3);
				}

				&--bottom {
					margin: 20px auto 0;
					text-align: center;
					color: $white;
					cursor: pointer;
					width: 55px;
					height: 60px;
					text-align: center;

					.icon-close {
						font-size: 3.0rem;
						display: block;
						line-height: 1.2;
					}
				}
			}
			
			&__list {
				margin: 10px -15px 0;
				border-top: 1px solid rgba($white, 0.3);
				
				&__item {
					border-bottom: 1px solid rgba($white, 0.3);
					
					&__link {
						color: $white;
						display: flex;
						align-items: center;
						padding: 15px;
						text-decoration: none;
						@include font-bold;
						position: relative;
						
						_:-ms-fullscreen, :root & {
							padding: 17px 15px 12px;
						}
						
						&__ico {
							margin: 0 10px 0 0;
						}
					}
					
					.gnav-accordion {
						&::before {
							display: block;
							position: absolute;
							top: 50%;
							right: 15px;
							transform: translate(0, -50%);
							color: $white;
							font-family: 'icomoon';
							line-height: 1.0;
							content: "\e90d";
							font-size: $l;
						}
						
						&.active {
							&::before {
									content: "\e90e";
							}
						}
					}
					
					&__sub {
						display: none;
						background: #d84577;
							
						&__item {
							border-bottom: 1px dotted rgba($white, 0.3);
							
							&:last-of-type {
								border-bottom: none;
							}
							
							&__link {
								color: $white;
								display: flex;
								padding: 12px 15px 12px 30px;
								text-decoration: none;
								font-size: $s;
								line-height: 1.5;
								
								.icon-angle-right {
									margin: 3px 10px 0 0;
									display: inline-block;
								}
							}
						}
					}
				}
			}
		}
	}
}

.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
	padding: 0 0 10px;
	background: $white;
	box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
	height: 70px;
	
	.header-inner {		
		padding: 5px 0;
		
		&__tab {
			display: none;
		}
	}
}
