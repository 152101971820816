@charset "utf-8";
/* ============================================================ */
/* /faq/ */
/* ============================================================ */
.faq {
	&__q {
		position: relative;
		padding: 15px 20px 15px 50px;
		border-bottom: 1px dotted $borderColor;
		line-height: 1.5;
		cursor: pointer;
				
		&__link {
			display: block;
			
			&::before {
				position: absolute;
				top: 15px;
				left: 0;
				content: 'Q';
				display: flex;
				align-items: center;
				justify-content: center;
				background: $white;
				border: 1px solid $baseColor;
				color: $baseColor;
				font-size: $m;
				@include circle(30);

				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					height: 25px;
					padding-top: 5px;
				}
			}
		}
		
		/*&::after {
			position: absolute;
			top: 50%;
			right: 5px;
			content: "\f107";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $l;
			transform: translateY(-50%);
		}
		&.active {
			color: $baseColor;
			&::after {
				transform: translateY(-50%) rotate(180deg);
			}
		}*/
	}
	
	&-detail {
		&__q {
			padding: 15px 20px 15px 50px;
			background: $lightColor;
			border-top: 1px dotted $borderColor;
			border-bottom: 1px dotted $borderColor;
			position: relative;

			&::before {
				position: absolute;
				top: 15px;
				left: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				content: 'Q';
				background: $white;
				border: 1px solid $baseColor;
				color: $baseColor;
				font-size: $xl;
				@include circle(30);

				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					height: 25px;
					padding-top: 5px;
				}
			}
		}
		
		&__a {
			position: relative;
			padding: 15px 20px 20px 50px;
			border-bottom: 1px dotted $borderColor;

			&::before {
				position: absolute;
				top: 15px;
				left: 10px;
				content: 'A';
				display: flex;
				align-items: center;
				justify-content: center;
				background: $baseColor;
				color: $white;
				font-size: $m;
				@include circle(30);

				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					height: 25px;
					padding-top: 5px;
				}
			}
		}
	}
	
	&-list {
		margin: 50px 0 0;
		
		.ttl03 {
			margin: 0 0 10px;
		}
	}
	
	&-detail {
		&__a {
			&__entry {
				img {
				max-width: 100% !important;
				height: auto !important;
			}
			
			iframe {
				width: 100%;
			}
			
			table {
				width: 100%;
				border: 1px solid $borderColor;
				table-layout: fixed;
				line-height: 1.6;
				word-break: break-all;
				word-wrap: break-word;

				th,
				td {
					padding: 10px;
					background: $white;
					border: 1px solid $borderColor;
					text-align: left;
					vertical-align: middle;
				}
				
				th {
					background: $lightColor;			
				}
				
				.bg-pink {
					background: $baseColor;
					color: $white;
				}
				.bg-pink-light {
					background: $lightColor;
				}
				.bg-beige {
					background: #f4f1ea;
				}
				.error {
					background: #fde9e9;
					color: #dd0000;
				}
			}
			
			.midashi03 {
				position: relative;
				margin: 0 0 25px;
				padding: 15px 0 0;
				font-size: $l;
				line-height: 1.4;
				@include font-bold;

				&::before,
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					height: 2px;
				}
				&::before {
					width: 100%;
					background: $borderColor;
				}
				&::after {
					width: 30%;
					background: $baseColor;
				}
			}
			.midashi04 {
				margin: 0 0 15px;
				padding: 0 0 10px;
				border-bottom: 1px solid $baseColor;
				font-size: $l;
				@include font-bold;
				line-height: 1.4;
			}
			.midashi05 {
				position: relative;
				margin: 0 0 15px;
				padding: 0 0 0 30px;
				font-size: $m;
				@include font-bold;
				line-height: 1.4;

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					width: 24px;
					height: 24px;
					background: url("/common/img/common/ico_ttl05.svg") 0 0 no-repeat;
				}
			}
			.midashi06 {
				margin: 0 0 15px;
				color: $baseColor;
				font-size: $m;
				@include font-bold;
				line-height: 1.4;
			}
			}
		}
	}
	
	.faq-btn {
		text-align: center;
		margin: 20px 0 0;
	}

}